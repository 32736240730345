import React, { Component } from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  registerLink,
  registerUser,
  clearMessages,
} from 'redux/modules/duck-auth';

import { Header } from 'common/Header';
import RegisterForm from './RegisterForm';
import LoaderPage from 'common/LoaderPage';

const NewHeader = styled(Header)`
  min-height: 600px;
  height: auto;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin-top: 50px;
`;

class Register extends Component {
  componentWillMount() {
    const { history } = this.props;
    const { token } = this.props.match.params;

    this.props.registerLink(token, history);
  }

  componentWillUnmount() {
    if (this.props.auth.error) {
      this.props.clearMessages();
    }
  }

  render() {
    const { auth, form, history } = this.props;
    return auth.validToken ? (
      <NewHeader landing={true}>
        <RegisterForm
          err={auth.error}
          loading={auth.loading}
          showName={false}
          onRegisterSubmit={() => {
            this.props.registerUser(form.registerForm.values, history);
          }}
        />
      </NewHeader>
    ) : (
      <LoaderPage color="accent1" />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerLink,
      registerUser,
      clearMessages,
    },
    dispatch
  );
}

function mapStateToProps({ auth, form }) {
  return { auth, form };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
