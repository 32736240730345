import React, { useState, useEffect, Fragment } from 'react';
import SvgIcon from 'common/icons/SvgIcon';
import colors from 'utils/colors';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSystemMap } from 'redux/modules/duck-admin';
import { baseFont, boldFont } from 'utils/fonts';
import Panel from './Panel';
import './LockerMap.css';
import { lock } from 'superagent';

const SelectWrapper = styled.div`
  grid-column: 2 / span 1;
  display: block;
  width: 221px;
  margin-bottom: 50px;
`;

const SelectWrap = styled.div`
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${colors.base_text3} transparent transparent;
  }
`;

const SelectField = styled.select`
  width: 100%;
  height: 25px;
  font-family: ${baseFont};
  font-size: 14px;
  font-weight: bold;
  color: ${colors.base_text3};
  background: white;
  border-radius: 3px;
  border-color: ${colors.base_text3};
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 16px;
`;

const Legend = () => (
  <figcaption className="lockers--legend">
    <span>
      <div className="lockers--legend--circle-dark">&#11044;</div>
      <h5 className="lockers--legend--header">Selected</h5>
    </span>
    <span>
      <div className="lockers--legend--circle-blue">&#11044;</div>
      <h5 className="lockers--legend--header">In Use</h5>
    </span>
  </figcaption>
);

/**
 * KIOSK is at middle, "M"  is at right and "S/M/L" type module is at left
 * Some mapping
 * S = 1 fr row unit in locker-doors grid
 * M = 2 fr row unit
 * L = 3 fr row unit
 * XL = 5 fr row unit
 * Kiosk = 7 fr row unit
 *
 * @param {*} param0
 */
const LockerMap = ({ fetchSystemMap, lockers }) => {
  const [mode, setMode] = useState('lockerMode');
  const [curPanel, setCurPanel] = useState('');

  useEffect(() => {
    fetchSystemMap(mode);
  }, [fetchSystemMap, mode]);
  console.log(lockers);
  return (
    <figure className="lockers--map" id="locker-map">
      {/* To be implemented eventually */}
      {/* <SelectWrapper>
        <SelectWrap>
          <SelectField onChange={setMode}>
            <option key={1} value="clickCollect">
              Click & Collect
            </option>
            <option key={2} value="lockerMode">
              Locker Mode
            </option>
          </SelectField>
        </SelectWrap>
      </SelectWrapper> */}
      <div className="lockers--container">
        {/* There is a mismanangement of field names between door_id and lockerNumber */}
        {lockers.map(({ _id, door_id: lockerNumber, door_detail }) => (
          <div key={_id} className="locker--container">
            <div className="locker--head" id="locker-head"></div>
            <div className="locker--body" id="locker-body">
              <div className="locker--leftside" id="locker-leftside"></div>
              <div className="locker--doors" id="locker-doors">
                {door_detail.map(
                  ({ door_id, available, panel_name, size, ada, _parcel }) => {
                    const uniqueDoorId = `${_id}-${door_id}`;
                    return (
                      <Fragment key={uniqueDoorId}>
                        <Panel
                          doorId={door_id}
                          lockerNumber={lockerNumber}
                          available={available}
                          panelName={panel_name}
                          size={size}
                          ada={ada}
                          startTime={_parcel ? _parcel.created_at : null}
                          expTime={_parcel ? _parcel.expiration : null}
                          selected={curPanel === uniqueDoorId ? true : false}
                          onClick={() => setCurPanel(uniqueDoorId)}
                        />
                      </Fragment>
                    );
                  }
                )}
              </div>
              <div className="locker--rightside" id="locker-rightside"></div>
            </div>
            <div className="locker--footer" id="locker-footer"></div>
          </div>
        ))}
      </div>
      <Legend />
    </figure>
  );
};

const mapStateToProps = (state) => {
  const { lockers } = state.admin;
  return { lockers };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchSystemMap }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LockerMap);
