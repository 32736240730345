import React from 'react';
import ReactDOM from 'react-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import styled, { css } from 'styled-components';
import colors from 'utils/colors';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format } from 'url';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import {
  fetchAllVacationRequests,
  updateVacationHold,
  fetchCounters,
  fetchAnalytics,
} from 'redux/modules/duck-admin';
import LongButton from 'common/buttons/longButton';
import { connect } from 'react-redux';

export let views = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
};

const localizer = momentLocalizer(moment);

const VacationsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  border: 1px solid #ddd;
  border-top: 3px solid #5c7fa7;
  ${'' /* font-size: 1em; */}
  font-family: 'Swiss721BTRegular';
  margin-top: 75.83px;
  margin-left: 15px;
  color: #083e66;
  font-family: 'Swiss721 BT';
  font-weight: bold;
  height: 997px;
  @media (max-width: 1350px) {
    overflow-y: scroll;
    height: auto;
    margin-bottom: 20px;
    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
`;

const VacationWrapperStylingPending = styled.div`
  color: ${colors.accent1};
  background-color: ${colors.pending};
  border: 2px solid ${colors.accent1};
  border-radius: 4px;
  text-align: left;
  padding-left: 2px;
`;

const VacationWrapperStylingApproved = styled.div`
  color: ${colors.white};
  background-color: ${colors.approved};
  padding-left: 2px;
  border-radius: 4px;
  text-align: left;
`;

const VacationsList = styled.ul`
  list-style: none;
  padding: 0;
  overflow: auto;
  @media (max-width: 1350px) {
    display: flex;
    flex-flow: row;
    &.vacList > h2 {
      display: none;
    }
    &.vacList > li {
      ${'' /* to distinguish between containers */}
      border-right: 5px solid #083E66;
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 450px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 0 auto 30px;
`;

const ApproveButton = styled(LongButton)`
  width: 150px;
  font-size: 16px;
  margin: 0 15px;
  border: 2px solid ${colors.action1};
  color: ${colors.action1};
`;

const DeclineButton = styled(LongButton)`
  width: 150px;
  font-size: 16px;
  margin: 0 15px;
  border: none;
  color: ${colors.action2};
  border: 2px solid ${colors.action2};
`;

const ColoredHorizontalLine = styled.hr`
  color: #e8e8e8;
  padding-left: 0;
  @media (max-width: 1350px) {
    display: none;
  }
`;

const DottedHorizontalLine = styled.hr`
  border-style: dotted;
  width: 70%;
  padding-left: 50px;
  margin-bottom: 0;
`;

const VacationWrapper = (vacation) => {
  return (
    <React.Fragment>
      {vacation.event.status === 'pending' && (
        <VacationWrapperStylingPending>
          <Tooltip
            placement="top"
            trigger={['hover']}
            prefixCls="rc-tooltip-expired-notice"
            overlay={
              <div className="calendarEvent">
                PENDING
                <hr />
                {vacation.event.name}
                <h3>
                  {vacation.event.start.toLocaleString().split(',')[0]} -{' '}
                  {vacation.event.end.toLocaleString().split(',')[0]}
                </h3>
              </div>
            }
          >
            <span>{vacation.event.name}</span>
          </Tooltip>
        </VacationWrapperStylingPending>
      )}
      {vacation.event.status === 'approved' && (
        <VacationWrapperStylingApproved>
          <Tooltip
            placement="top"
            trigger={['hover']}
            prefixCls="rc-tooltip-expired-notice"
            overlay={
              <div>
                APPROVED
                <hr />
                {vacation.event.name}
                <h3>
                  {vacation.event.start.toLocaleString().split(',')[0]} -{' '}
                  {vacation.event.end.toLocaleString().split(',')[0]}
                </h3>
              </div>
            }
          >
            <span>{vacation.event.name}</span>
          </Tooltip>
        </VacationWrapperStylingApproved>
      )}
    </React.Fragment>
  );
};

const RcToolTipWrapper = (props) => {
  return ReactDOM.createPortal(
    <Tooltip
      placement="top"
      trigger={['hover']}
      prefixCls="rc-tooltip-expired-notice"
      overlay={<p>System capacity for this month</p>}
    >
      <span id="capacityText">{props.text}%</span>
    </Tooltip>,
    document.getElementsByClassName('rbc-btn-group')[0]
  );
};

class CalendarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vacations: [],
      labelInnerText: '',
      currentView: 'month',
      currentDate: new Date(),
      loadRbcWrapper: false,
      capacityTotal: '',
      dataLoaded: false,
      vacationApprovedOrDenied: false,
    };
  }

  // alter vacations will map over vacation array and setState accordingly
  alterVacations(vacs) {
    let updatedVacations = [...vacs];
    updatedVacations = updatedVacations.map((vacation) => {
      return Object.assign({}, vacation, {
        start: moment(vacation.hold_from).toDate(),
        end: moment(vacation.hold_to).toDate(),
        endOffset: moment(vacation.hold_to) // to counter block on calendar being offset by one day too early
          .add(1, 'days')
          .format(),
        allDay: true,
      });
    });
    this.setState({
      vacations: updatedVacations,
      dataLoaded: true,
    });
  }

  componentDidMount() {
    const { selectedSystem } = this.props;

    if (localStorage.user_token && selectedSystem) {
      this.props.fetchAnalytics(
        selectedSystem.system._id,
        selectedSystem.system.timezone,
        this.props.history
      );
    }
    if (this.props.vacations !== null) {
      this.alterVacations(this.props.vacations);
    }

    let todayPrevNextToolbar = document.getElementsByClassName(
        // calendarLabel is created here
        'rbc-btn-group'
      )[0],
      labelNode = document.createElement('span');
    labelNode.innerText = moment(new Date()).format('MMMM YYYY');
    labelNode.setAttribute('id', 'calendarLabel');
    todayPrevNextToolbar && todayPrevNextToolbar.appendChild(labelNode);
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedSystem } = this.props;

    if (prevState.labelInnerText !== this.state.labelInnerText) {
      document.getElementById(
        'calendarLabel'
      ).innerText = this.state.labelInnerText;
    }
    if (
      document.getElementsByClassName('rbc-btn-group')[0] &&
      !this.state.loadRbcWrapper
    ) {
      this.setState({ loadRbcWrapper: true });
    }

    if (
      this.props.analytics &&
      this.props.analytics.capacity &&
      this.state.capacityTotal === ''
    ) {
      this.setState({ capacityTotal: this.props.analytics.capacity.total });
    }

    if (
      this.props.vacations !== prevProps.vacations &&
      !this.state.dataOnMount
    ) {
      this.setState({ dataOnMount: true });
      this.props.fetchAllVacationRequests(
        selectedSystem.system._id,
        this.props.history
      );

      this.alterVacations(this.props.vacations);
    }

    if (
      JSON.stringify(this.props.vacations) !==
      JSON.stringify(prevProps.vacations)
    ) {
      this.setState({ vacationApprovedOrDenied: false });
      this.props.fetchAllVacationRequests(
        selectedSystem.system._id,
        this.props.history
      );

      this.alterVacations(this.props.vacations);
    }
  }

  render() {
    let pendingVacations = [...this.state.vacations].filter(
      (vacation) => vacation.status === 'pending'
    );

    return (
      <div
        className="calendar"
        style={{
          display: 'flex',
          flexFlow: 'row wrap-reverse',
          alignItems: 'start',
        }}
      >
        <Calendar
          style={{ height: 1000, flex: 5, minWidth: '600px' }}
          localizer={localizer}
          events={[...this.state.vacations]}
          startAccessor="start"
          endAccessor="endOffset"
          allDayAccessor="true"
          views={['month', 'week', 'day']}
          step={30}
          onView={(view) => {
            this.setState({ currentView: view });
            if (view === 'month') {
              this.setState({
                labelInnerText: moment(this.state.currentDate).format(
                  'MMMM YYYY'
                ),
              });
            } else if (view === 'week') {
              let weekLabelSameMonth =
                moment(this.state.currentDate)
                  .startOf('week')
                  .format('LL')
                  .split(',')[0] +
                ' - ' +
                moment(this.state.currentDate)
                  .endOf('week')
                  .format('D');
              let weekLabelDifferentMonth =
                moment(this.state.currentDate)
                  .startOf('week')
                  .format('LL')
                  .split(',')[0] +
                ' - ' +
                moment(this.state.currentDate)
                  .endOf('week')
                  .format('LL')
                  .split(',')[0];

              moment(this.state.currentDate)
                .startOf('week')
                .format('MM') ===
              moment(this.state.currentDate)
                .endOf('week')
                .format('MM')
                ? this.setState({ labelInnerText: weekLabelSameMonth })
                : this.setState({ labelInnerText: weekLabelDifferentMonth });
            } else if (view === 'day') {
              let formattedDay = moment(this.state.currentDate)
                  .format('LLLL')
                  .split(','),
                formattedDayLabel = formattedDay[0] + ', ' + formattedDay[1];
              this.setState({ labelInnerText: formattedDayLabel });
            }
          }}
          onNavigate={(date) => {
            if (this.state.currentView === 'month') {
              this.setState({
                labelInnerText: moment(date).format('MMMM YYYY'),
              });
            } else if (this.state.currentView === 'week') {
              let weekLabelSameMonth =
                moment(date)
                  .startOf('week')
                  .format('LL')
                  .split(',')[0] +
                ' - ' +
                moment(date)
                  .endOf('week')
                  .format('D');
              let weekLabelDifferentMonth =
                moment(date)
                  .startOf('week')
                  .format('LL')
                  .split(',')[0] +
                ' - ' +
                moment(date)
                  .endOf('week')
                  .format('LL')
                  .split(',')[0];

              moment(date)
                .startOf('week')
                .format('MM') ===
              moment(date)
                .endOf('week')
                .format('MM')
                ? this.setState({ labelInnerText: weekLabelSameMonth })
                : this.setState({ labelInnerText: weekLabelDifferentMonth });
            } else if (this.state.currentView === 'day') {
              let formattedDay = moment(date)
                  .format('LLLL')
                  .split(','),
                formattedDayLabel = formattedDay[0] + ', ' + formattedDay[1];
              this.setState({ labelInnerText: formattedDayLabel });
            }
            this.setState({ currentDate: date });
          }}
          components={{
            eventWrapper: VacationWrapper,
          }}
        />
        {pendingVacations.length > 0 && (
          <VacationsListContainer>
            <VacationsList className="vacList">
              <h2 style={{ paddingLeft: 50, fontFamily: 'Swiss721BTRegular' }}>
                Requests
              </h2>
              {pendingVacations.map((vacation, vacation_index) => (
                <li
                  key={vacation._id}
                  style={{
                    listStyleType: 'none',
                    height: '200px',
                    fontFamily: 'Swiss721BTRegular',
                  }}
                >
                  <p
                    style={{
                      paddingLeft: 50,
                      marginBottom: 0,
                      color: '#3A3A3A',
                      fontSize: '20px',
                    }}
                  >
                    {vacation.name}
                  </p>
                  <DottedHorizontalLine />
                  <br />
                  <h6
                    style={{
                      paddingLeft: 50,
                      marginTop: 0,
                      marginBottom: 0,
                      color: '#999999',
                    }}
                  >
                    HOLD REQUEST
                  </h6>
                  <p
                    style={{ paddingLeft: 50, marginTop: 0, color: '#3A3A3A' }}
                  >
                    <br />
                    {vacation.start.toLocaleString().split(',')[0]} -{' '}
                    {vacation.end.toLocaleString().split(',')[0]}
                  </p>
                  <ButtonWrapper>
                    <ApproveButton
                      textColor="action1"
                      backgroundColor="white"
                      onClick={async () => {
                        const idx = vacation_index;
                        await this.props.updateVacationHold(
                          idx,
                          'approved',
                          this.props.selectedSystem._id,
                          pendingVacations
                        );

                        this.setState({
                          vacationApprovedOrDenied: true,
                        });
                      }}
                    >
                      Approve
                    </ApproveButton>
                    <DeclineButton
                      textColor="action2"
                      backgroundColor="white"
                      onClick={async () => {
                        const idx = vacation_index;
                        this.props.updateVacationHold(
                          idx,
                          'declined',
                          this.props.selectedSystem._id,
                          pendingVacations
                        );

                        this.setState({
                          vacationApprovedOrDenied: true,
                        });
                      }}
                    >
                      Decline
                    </DeclineButton>
                  </ButtonWrapper>
                  <ColoredHorizontalLine />
                </li>
              ))}
            </VacationsList>
          </VacationsListContainer>
        )}
        {this.state.loadRbcWrapper && (
          <RcToolTipWrapper text={this.state.capacityTotal} />
        )}
      </div>
    );
  }
}

function mapStateToProps({ admin }) {
  const { vacations, fetchVacationLoading, selectedSystem, analytics } = admin;
  return {
    vacations,
    fetchVacationLoading,
    selectedSystem,
    analytics,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllVacationRequests,
      updateVacationHold,
      fetchCounters,
      fetchAnalytics,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarComponent);
