import React from 'react';
import './locker_icons.css';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.81 315.41">
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Doors">
        <line
          className="locker-icon"
          x1="0.35"
          y1="315.05"
          x2="153.45"
          y2="315.05"
        />
        <line class="cls-1" x1="153.45" y1="315.05" x2="153.45" y2="0.35" />
        <line class="cls-1" x1="0.35" y1="0.35" x2="0.35" y2="315.05" />
        <line class="cls-1" x1="153.45" y1="0.35" x2="0.35" y2="0.35" />
        <line class="cls-1" x1="9.15" y1="165.85" x2="6.05" y2="165.85" />
        <line class="cls-1" x1="6.05" y1="184.95" x2="9.15" y2="184.95" />
        <line class="cls-1" x1="9.15" y1="165.85" x2="9.15" y2="165.85" />
        <line class="cls-1" x1="9.15" y1="184.95" x2="9.15" y2="184.95" />
        <polyline
          class="cls-1"
          points="9.96 165.85 9.96 165.85 9.85 165.85 9.75 165.85 9.65 165.85 9.55 165.85 9.46 165.85 9.25 165.85 9.15 165.85"
        />
        <line class="cls-1" x1="9.15" y1="165.85" x2="9.15" y2="184.95" />
        <polyline
          class="cls-1"
          points="9.15 184.96 9.25 184.96 9.46 184.96 9.55 184.96 9.65 184.96 9.75 184.96 9.85 184.96 9.96 184.96"
        />
        <line class="cls-1" x1="6.05" y1="166.85" x2="6.05" y2="166.85" />
        <line class="cls-1" x1="6.05" y1="183.95" x2="6.05" y2="183.95" />
        <line class="cls-1" x1="11.15" y1="185.45" x2="11.15" y2="165.35" />
        <line class="cls-1" x1="9.95" y1="184.95" x2="9.95" y2="165.85" />
        <line class="cls-1" x1="3.55" y1="165.35" x2="3.55" y2="185.45" />
        <line class="cls-1" x1="10.35" y1="164.65" x2="4.25" y2="164.65" />
        <line class="cls-1" x1="4.25" y1="186.15" x2="10.35" y2="186.15" />
        <polyline class="cls-1" points="9.96 165.85 9.96 165.85 9.85 165.85" />
        <line class="cls-1" x1="9.95" y1="165.85" x2="9.95" y2="165.85" />
        <polyline class="cls-1" points="9.96 184.96 9.96 184.96 9.85 184.96" />
        <line class="cls-1" x1="9.95" y1="184.95" x2="9.95" y2="184.95" />
        <line class="cls-1" x1="11.15" y1="165.35" x2="11.05" y2="165.35" />
        <polyline
          class="cls-1"
          points="10.35 164.65 10.55 164.65 10.65 164.65 10.75 164.75 10.85 164.85 10.96 164.96 11.05 165.06 11.05 165.25 11.15 165.35"
        />
        <line class="cls-1" x1="10.35" y1="164.65" x2="10.35" y2="164.65" />
        <line class="cls-1" x1="10.35" y1="186.15" x2="10.35" y2="186.15" />
        <polyline
          class="cls-1"
          points="11.15 185.46 11.05 185.56 11.05 185.75 10.96 185.85 10.85 185.96 10.75 186.06 10.65 186.15 10.55 186.15 10.35 186.15"
        />
        <line class="cls-1" x1="11.05" y1="185.45" x2="11.15" y2="185.45" />
        <line class="cls-1" x1="11.05" y1="165.35" x2="11.05" y2="185.45" />
        <line class="cls-1" x1="3.55" y1="185.45" x2="3.55" y2="165.35" />
        <line class="cls-1" x1="4.25" y1="164.65" x2="10.35" y2="164.65" />
        <line class="cls-1" x1="10.35" y1="186.15" x2="4.25" y2="186.15" />
        <polyline
          class="cls-1"
          points="10.35 164.65 10.46 164.65 10.55 164.65"
        />
        <line class="cls-1" x1="10.35" y1="164.65" x2="10.35" y2="164.65" />
        <line class="cls-1" x1="10.35" y1="186.15" x2="10.35" y2="186.15" />
        <polyline
          class="cls-1"
          points="10.55 186.15 10.55 186.15 10.46 186.15 10.35 186.15"
        />
        <line class="cls-1" x1="11.05" y1="165.35" x2="11.05" y2="165.35" />
        <polyline
          class="cls-1"
          points="10.55 164.65 10.75 164.75 10.85 164.75 10.96 164.85 10.96 164.96 11.05 165.06 11.05 165.25 11.05 165.35"
        />
        <polyline
          class="cls-1"
          points="11.05 185.46 11.05 185.56 11.05 185.75 10.96 185.85 10.96 185.96 10.85 186.06 10.75 186.06 10.55 186.15"
        />
        <line class="cls-1" x1="11.05" y1="185.45" x2="11.05" y2="185.45" />
        <line class="cls-1" x1="10.55" y1="164.75" x2="10.55" y2="164.65" />
        <line class="cls-1" x1="10.55" y1="164.65" x2="10.55" y2="164.65" />
        <line class="cls-1" x1="10.55" y1="186.05" x2="10.55" y2="186.15" />
        <line class="cls-1" x1="10.55" y1="186.15" x2="10.55" y2="186.15" />
        <line class="cls-1" x1="3.55" y1="165.35" x2="3.55" y2="165.35" />
        <line class="cls-1" x1="4.25" y1="164.65" x2="4.25" y2="164.65" />
        <polyline
          class="cls-1"
          points="3.55 165.35 3.55 165.25 3.65 165.06 3.65 164.96 3.75 164.85 3.85 164.75 4.05 164.65 4.16 164.65 4.25 164.65"
        />
        <line class="cls-1" x1="4.25" y1="186.15" x2="4.25" y2="186.15" />
        <line class="cls-1" x1="3.55" y1="185.45" x2="3.55" y2="185.45" />
        <polyline
          class="cls-1"
          points="4.25 186.15 4.16 186.15 4.05 186.15 3.85 186.06 3.75 185.96 3.65 185.85 3.65 185.75 3.55 185.56 3.55 185.46"
        />
        <line class="cls-1" x1="9.95" y1="184.95" x2="9.95" y2="165.85" />
        <polyline class="cls-1" points="9.96 165.85 9.96 165.85 9.85 165.75" />
        <polyline class="cls-1" points="9.96 165.85 9.96 165.85 10.05 165.85" />
        <polyline class="cls-1" points="9.96 184.96 9.96 184.96 10.05 184.96" />
        <polyline class="cls-1" points="9.96 184.96 9.96 184.96 9.85 185.06" />
        <line class="cls-1" x1="4.25" y1="164.65" x2="4.25" y2="164.65" />
        <polyline
          class="cls-1"
          points="3.85 164.75 3.96 164.75 4.16 164.65 4.25 164.65"
        />
        <line class="cls-1" x1="4.25" y1="186.15" x2="4.25" y2="186.15" />
        <polyline
          class="cls-1"
          points="4.25 186.15 4.16 186.15 3.96 186.06 3.85 186.06"
        />
        <polyline class="cls-1" points="3.55 165.35 3.55 165.35 3.65 165.35" />
        <polyline
          class="cls-1"
          points="3.55 165.35 3.55 165.25 3.65 165.06 3.65 164.96 3.75 164.85 3.85 164.75"
        />
        <polyline class="cls-1" points="3.55 185.46 3.55 185.46 3.65 185.46" />
        <polyline
          class="cls-1"
          points="3.85 186.06 3.75 185.96 3.65 185.85 3.65 185.75 3.55 185.56 3.55 185.46"
        />
        <polyline class="cls-1" points="3.85 164.75 3.85 164.75 3.85 164.85" />
        <line class="cls-1" x1="3.85" y1="164.75" x2="3.85" y2="164.75" />
        <line class="cls-1" x1="3.85" y1="186.05" x2="3.85" y2="186.05" />
        <polyline class="cls-1" points="3.85 186.06 3.85 186.06 3.85 185.96" />
        <line class="cls-1" x1="6.15" y1="165.85" x2="9.15" y2="165.85" />
        <polyline
          class="cls-1"
          points="9.15 165.85 9.25 165.85 9.46 165.85 9.55 165.85 9.65 165.85 9.75 165.85 9.85 165.85"
        />
        <line class="cls-1" x1="9.85" y1="165.85" x2="9.85" y2="165.75" />
        <line class="cls-1" x1="9.85" y1="185.05" x2="9.85" y2="184.95" />
        <polyline
          class="cls-1"
          points="9.85 184.96 9.85 184.96 9.75 184.96 9.65 184.96 9.55 184.96 9.46 184.96 9.25 184.96 9.15 184.96"
        />
        <line class="cls-1" x1="9.15" y1="184.95" x2="6.15" y2="184.95" />
        <line class="cls-1" x1="6.05" y1="166.85" x2="6.05" y2="165.85" />
        <line class="cls-1" x1="6.05" y1="184.85" x2="6.05" y2="183.95" />
        <line class="cls-1" x1="6.05" y1="183.95" x2="6.05" y2="183.95" />
        <line class="cls-1" x1="6.05" y1="183.95" x2="6.05" y2="166.85" />
        <line class="cls-1" x1="6.05" y1="166.85" x2="6.05" y2="166.85" />
        <line class="cls-1" x1="6.05" y1="165.85" x2="6.05" y2="165.85" />
        <line class="cls-1" x1="6.05" y1="165.85" x2="6.05" y2="165.85" />
        <polyline class="cls-1" points="6.16 165.85 6.16 165.85 6.05 165.85" />
        <line class="cls-1" x1="6.15" y1="165.75" x2="6.15" y2="165.85" />
        <line class="cls-1" x1="6.05" y1="184.85" x2="6.05" y2="184.95" />
        <line class="cls-1" x1="6.05" y1="184.95" x2="6.05" y2="184.85" />
        <line class="cls-1" x1="6.15" y1="184.95" x2="6.15" y2="185.05" />
        <polyline class="cls-1" points="6.16 184.96 6.16 184.96 6.05 184.96" />
        <line class="cls-1" x1="9.85" y1="165.65" x2="9.85" y2="165.75" />
        <line class="cls-1" x1="9.85" y1="165.75" x2="6.15" y2="165.75" />
        <polyline class="cls-1" points="9.85 185.06 9.85 185.06 9.85 185.15" />
        <line class="cls-1" x1="6.15" y1="185.05" x2="9.85" y2="185.05" />
        <polyline
          class="cls-1"
          points="6.16 165.75 6.16 165.75 6.05 165.75 6.05 165.85"
        />
        <polyline class="cls-1" points="6.16 165.75 6.16 165.75 6.16 165.65" />
        <line class="cls-1" x1="6.05" y1="165.85" x2="6.05" y2="184.95" />
        <polyline class="cls-1" points="5.96 165.85 5.96 165.85 6.05 165.85" />
        <line class="cls-1" x1="6.15" y1="185.15" x2="6.15" y2="185.05" />
        <polyline class="cls-1" points="6.16 185.06 6.16 185.06 6.05 184.96" />
        <polyline class="cls-1" points="5.96 184.96 5.96 184.96 6.05 184.96" />
        <polyline
          class="cls-1"
          points="10.55 164.65 10.55 164.65 10.46 164.65 10.35 164.65"
        />
        <line class="cls-1" x1="10.35" y1="164.65" x2="4.25" y2="164.65" />
        <polyline
          class="cls-1"
          points="4.25 164.65 4.16 164.65 4.05 164.75 3.85 164.75"
        />
        <line class="cls-1" x1="4.25" y1="186.15" x2="10.35" y2="186.15" />
        <polyline
          class="cls-1"
          points="10.35 186.15 10.46 186.15 10.55 186.15"
        />
        <polyline
          class="cls-1"
          points="3.85 186.06 4.05 186.06 4.16 186.15 4.25 186.15"
        />
        <line class="cls-1" x1="3.65" y1="165.35" x2="3.65" y2="185.45" />
        <polyline
          class="cls-1"
          points="3.65 185.46 3.65 185.56 3.65 185.65 3.75 185.85 3.75 185.96 3.85 185.96"
        />
        <polyline
          class="cls-1"
          points="3.85 164.85 3.75 164.85 3.75 164.96 3.65 165.15 3.65 165.25 3.65 165.35"
        />
        <line class="cls-1" x1="3.85" y1="164.75" x2="4.95" y2="165.35" />
        <polyline class="cls-1" points="3.85 164.75 3.85 164.75 3.85 164.85" />
        <line class="cls-1" x1="4.85" y1="165.35" x2="3.85" y2="164.85" />
        <line class="cls-1" x1="3.85" y1="185.95" x2="4.85" y2="185.45" />
        <polyline class="cls-1" points="3.85 185.96 3.85 185.96 3.85 186.06" />
        <line class="cls-1" x1="4.95" y1="185.45" x2="3.85" y2="186.05" />
        <line class="cls-1" x1="11.05" y1="185.45" x2="11.05" y2="165.35" />
        <polyline
          class="cls-1"
          points="11.05 165.35 11.05 165.25 11.05 165.15 10.96 164.96 10.85 164.85 10.65 164.75 10.55 164.75"
        />
        <polyline
          class="cls-1"
          points="10.55 186.06 10.65 186.06 10.85 185.96 10.85 185.85 10.96 185.85 11.05 185.65 11.05 185.56 11.05 185.46"
        />
        <polyline
          class="cls-1"
          points="6.16 165.65 6.16 165.65 6.05 165.75 5.96 165.75 5.96 165.85"
        />
        <line class="cls-1" x1="6.15" y1="165.65" x2="9.85" y2="165.65" />
        <polyline
          class="cls-1"
          points="10.05 165.85 10.05 165.85 10.05 165.75 9.96 165.75 9.85 165.65"
        />
        <line class="cls-1" x1="10.05" y1="165.85" x2="10.05" y2="184.95" />
        <polyline
          class="cls-1"
          points="9.85 185.15 9.96 185.06 10.05 185.06 10.05 184.96"
        />
        <line class="cls-1" x1="9.85" y1="185.15" x2="6.15" y2="185.15" />
        <polyline
          class="cls-1"
          points="5.96 184.96 5.96 184.96 5.96 185.06 6.05 185.06 6.16 185.06 6.16 185.15"
        />
        <line class="cls-1" x1="5.95" y1="184.95" x2="5.95" y2="165.85" />
        <line class="cls-1" x1="10.55" y1="164.75" x2="4.95" y2="165.35" />
        <polyline
          class="cls-1"
          points="10.55 164.75 10.55 164.75 10.55 164.65"
        />
        <line class="cls-1" x1="4.95" y1="165.35" x2="10.55" y2="164.65" />
        <line class="cls-1" x1="10.55" y1="186.15" x2="4.95" y2="185.45" />
        <polyline
          class="cls-1"
          points="10.55 186.15 10.55 186.15 10.55 186.06"
        />
        <line class="cls-1" x1="4.95" y1="185.45" x2="10.55" y2="186.05" />
        <polyline class="cls-1" points="4.96 165.35 4.96 165.35 4.85 165.35" />
        <line class="cls-1" x1="4.95" y1="165.35" x2="4.95" y2="165.35" />
        <polyline class="cls-1" points="4.85 185.46 4.85 185.46 4.96 185.46" />
        <line class="cls-1" x1="4.95" y1="185.45" x2="4.95" y2="185.45" />
        <polyline class="cls-1" points="4.85 185.46 4.85 185.46 4.96 185.46" />
        <line class="cls-1" x1="4.95" y1="165.35" x2="4.95" y2="185.45" />
        <polyline class="cls-1" points="4.85 165.35 4.85 165.35 4.96 165.35" />
        <line class="cls-1" x1="4.85" y1="185.45" x2="4.85" y2="165.35" />
      </g>
    </g>
  </svg>
);
