export const registerFields = [
  {
    label: 'Create Password',
    name: 'password',
    type: 'password',
    placeholder: 'Create Password',
  },
  {
    label: 'Confirm Password',
    name: 'confirm',
    type: 'password',
    placeholder: 'Confirm Password',
  },
];

export const inviteUserFields = [
  {
    type: 'textarea',
    name: 'emails',
    placeholder: "Add users' email addresses, separated by commas",
  },
];

export const invitationFields = [
  {
    type: 'textarea',
    name: 'emails',
    placeholder: "Add users' email addresses, separated by commas",
  },
];

export const notiSwitches = [
  { label: 'SMS Notification', name: 'sms_notification', type: 'checkbox' },
  { label: 'Email Notification', name: 'email_notification', type: 'checkbox' },
  { label: 'ADA Compliant', name: 'ada', type: 'checkbox' },
];

export const loginFields = [
  { label: 'email', name: 'email', type: 'text', placeholder: 'Email' },
  {
    label: 'Password',
    name: 'password',
    type: 'password',
    placeholder: 'Password',
  },
];

export const forgotPasswordFields = [
  { label: 'Email', name: 'email', type: 'text', placeholder: 'Email' },
];

export const resetPasswordFields = [
  {
    label: 'New Password',
    name: 'password',
    type: 'password',
    placeholder: 'Password',
  },
  {
    label: 'Confirm Password',
    name: 'confirm',
    type: 'password',
    placeholder: 'Confirm Password',
  },
];

export const settingsFields = {
  name: [
    { label: 'First Name', name: 'first_name', type: 'text' },
    { label: 'Last Name', name: 'last_name', type: 'text' },
  ],
  email: [{ label: 'Email', name: 'email', type: 'text' }],
  secondary_email: [
    { label: 'Secondary Email', name: 'secondary_email', type: 'text' },
  ],
  password: [
    { label: 'Current Password', name: 'current_password', type: 'password' },
    { label: 'New Password', name: 'password', type: 'password' },
    { label: 'Confirm Password', name: 'confirm', type: 'password' },
  ],
  phone: [{ label: 'Phone', name: 'phone', type: 'text' }],
  timezone: [{ label: 'Timezone', name: 'timezone' }],
  notifications: [
    { label: 'Dropoff Notification', name: 'dropoff', type: 'checkbox' },
    { label: 'Picked Up Notification', name: 'picked_up', type: 'checkbox' },
    {
      label: 'Expiring Package Reminder',
      name: 'expiring',
      type: 'checkbox',
    },
    { label: 'Pick Up Reminder', name: 'pickup_reminder', type: 'checkbox' },
    {
      label: 'Secondary Email Notification',
      name: 'notifications.secondary_email_notification',
      type: 'checkbox',
    },
  ],
};

export const editProfileFields = [
  { label: 'Email', name: 'email', type: 'text' },
  [
    { label: 'First Name', name: 'first_name', type: 'text' },
    { label: 'Last Name', name: 'last_name', type: 'text' },
  ],
  { label: 'Secondary Email', name: 'secondary_email', type: 'text' },
];

export const systemSettingsFields = [
  { label: 'System Name', name: 'nickname', type: 'text' },
  [
    { label: 'Address Line 1', name: 'address1', type: 'text' },
    { label: 'Address Line 2', name: 'address2', type: 'text' },
  ],
  [
    { label: 'City', name: 'city', type: 'text' },
    { label: 'State', name: 'state', type: 'text' },
    { label: 'Postal Code', name: 'zip', type: 'text' },
  ],
  [
    { label: 'Email', name: 'email', type: 'text' },
    { label: 'Contact Number', name: 'phone', type: 'text' },
  ],
];

export const vacationLimit = [
  { value: 0, label: 'Off' },
  { value: 1, label: '1 day' },
  { value: 2, label: '2 days' },
  { value: 3, label: '3 days' },
  { value: 4, label: '4 days' },
  { value: 5, label: '5 days' },
  { value: 6, label: '6 days' },
  { value: 7, label: '7 days' },
  { value: 8, label: '8 days' },
  { value: 9, label: '9 days' },
  { value: 10, label: '10 days' },
];

export const parcelExpiration = [
  { value: 1, label: '1 day' },
  { value: 2, label: '2 days' },
  { value: 3, label: '3 days' },
  { value: 4, label: '4 days' },
  { value: 5, label: '5 days' },
  { value: 6, label: '6 days' },
  { value: 7, label: '7 days' },
  { value: 8, label: '8 days' },
  { value: 9, label: '9 days' },
  { value: 10, label: '10 days' },
  { value: 15, label: '15 days' },
  { value: 30, label: '30 days' },
];

export const managerPickUpTime = [
  { value: 'Morning', label: 'Morning' },
  { value: 'End of Day', label: 'End of Day' },
  { value: 'Off', label: 'Off' },
];

export const userReminderFrequency = [
  { value: 'Two-Day', label: 'Two-Day' },
  { value: 'Three-Day', label: 'Three-Day' },
  { value: 'Daily', label: 'Daily' },
  { value: 'Off', label: 'Off' },
];

export const timezones = [
  { value: 'Europe/Bucharest', label: 'Europe/Eastern', key: 0 },
  { value: 'Europe/Berlin', label: 'Europe/Central', key: 1 },
  { value: 'Europe/Paris', label: 'Europe/Western', key: 2 },
];

export const notifOptions = [
  {
    value: 'None',
    label: 'None',
    key: 0,
  },
  { value: 'SMS', label: 'SMS', key: 1 },
  {
    value: 'EMAIL',
    label: 'EMAIL',
    key: 2,
  },
  {
    value: 'BOTH',
    label: 'BOTH SMS/EMAIL',
    key: 3,
  },
];
