import React from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { timezones } from 'common/forms/formFields';
import './dropdownMenu.css';

export default class TimezoneDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    return (
      <div className="timezonedropdown__caret--right">
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css"
        />
        <Dropdown
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
          size="lg"
        >
          <DropdownToggle
            style={{
              color: '#083e66',
              backgroundColor: 'white',
              display: 'block',
              margin: '30px 0px 0px 45px',
              width: '275px',
              textAlign: 'left',
              fontSize: '14px',
              borderColor: 'rgba(51,51,51,0.3)',
            }}
            caret
          >
            {this.props.value}
          </DropdownToggle>
          <DropdownMenu>
            {timezones.map((timezone) => (
              <div key={timezone.key} className="dropdownMenu">
                <DropdownItem
                  id={timezone.label}
                  onClick={this.props.onChange}
                  value={timezone.value}
                >
                  {timezone.label}
                </DropdownItem>
                <DropdownItem divider />
              </div>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
