import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import {
  vacationLimit,
  managerPickUpTime,
  userReminderFrequency,
  timezones,
  parcelExpiration,
} from 'common/forms/formFields';

import ErrorMessage from '../Error';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';

const Wrapper = styled.div`
  display: flex;
  align-self: flex-start;
  margin: 25px 25px;
  width: 100%;
  ${mobile(css`
    width: 100%;
    margin: 25px 0;
  `)};

  ${(p) =>
    p.name === 'timezone'
      ? `flex-direction: column;`
      : `flex-direction: row; justify-content:space-between`};
`;

const Label = styled.span`
  text-align: left;
  margin: 10px 0;
  color: ${colors.base_text3};
  font-size: 16px;
  ${mobile(css`
    float: left;
  `)};
`;

const SelectField = styled.select`
  height: 42px;
  float: left;
  padding: 0px 5px;
  width: 350px;
  color: #083e66;
  font-family: ${baseFont};
  font-size: 16px;
  line-height: 19px;

  background: transparent;
  border: 1px solid #90a1b5;
  border-radius: 2px;
  letter-spacing: 2px;
  text-transform: uppercase;
  ${mobile(css`
    width: 100%;
    margin: 25px 0;
  `)};
  ${(p) =>
    p.name === 'timezone' ? `width: 350px;` : `width: 200px; float: right;`};
`;

const SelectWrap = styled.div`
  position: relative;
  width: 350px;
  margin-right: 50px;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${colors.base_text3} transparent transparent;
  }
`;

export default (props) => {
  const {
    input,
    label,
    type,
    meta,
    defaultValueTime,
    defaultValuePickUpTime,
    defaultValueNotificationFreq,
    defaultValueVacationLimit,
    defaultValueParcelExpiration,
  } = props;
  let options;
  if (input.name === 'vacation_limit') {
    options = vacationLimit;
    if (input.value === '') {
      input.value = defaultValueVacationLimit;
    }
  } else if (input.name === 'parcel_expiration') {
    options = parcelExpiration;
    if (input.value === '') {
      input.value = defaultValueParcelExpiration;
    }
  } else if (input.name === 'manager_pick_up_time') {
    options = managerPickUpTime;
    if (input.value === '') {
      input.value = defaultValuePickUpTime;
    }
  } else if (input.name === 'notification_freq') {
    options = userReminderFrequency;
    if (input.value === '') {
      input.value = defaultValueNotificationFreq;
    }
  } else if (input.name === 'timezone') {
    options = timezones;
    if (input.value === '') {
      input.value = defaultValueTime;
    }
  }
  return (
    <Wrapper {...input}>
      <Label>{label}:</Label>
      <SelectWrap>
        <SelectField {...input} type={type}>
          {_.map(options, (option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectField>
      </SelectWrap>
      {!meta.active && meta.touched && meta.error && (
        <ErrorMessage className="error">{meta.error}</ErrorMessage>
      )}
    </Wrapper>
  );
};
