import React, { Component } from 'react';
import { VictoryArea, VictoryChart, VictoryAxis } from 'victory';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';

class AreaChart extends Component {
  render() {
    return (
      <VictoryChart
        domainPadding={{ x: 25, y: 25 }}
        height={400}
        style={{
          parent: {
            marginTop: '-15px',
          },
          axis: { stroke: '#756f6a' },
          axisLabel: { fontSize: 200, padding: 30 },
        }}
      >
        <VictoryArea
          barRatio={0.9}
          style={{
            data: {
              fill: colors.primary_blue,
            },
            labels: {
              fontSize: 15,
              padding: 5,
              fill: colors.primary_blue,
              fontFamily: baseFont,
            },
          }}
          animate={{
            duration: 1500,
            onLoad: { duration: 500 },
          }}
          x={this.props.x}
          y={this.props.y}
          data={this.props.data}
          style={{ data: { fill: this.props.color } }}
        />
        <VictoryAxis
          style={{
            axis: { stroke: this.props.color },
            axisLabel: {
              fontSize: 15,
              padding: 30,
              fill: colors.primary_blue,
              fontFamily: baseFont,
            },
          }}
        />
      </VictoryChart>
    );
  }
}

export default AreaChart;
