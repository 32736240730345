import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchAllVacationRequests,
  selectSystem,
  currentUserAnalytics,
  updateVacationHold,
  fetchCounters,
} from 'redux/modules/duck-admin';
import { fetchDirectory } from 'redux/modules/duck-auth';
import _ from 'lodash';
import moment from 'moment';
import Pluralize from 'react-pluralize';
import AnalyticsCard from 'common/AnalyticsCard';
import AreaChart from 'common/d3/AreaChart';
import LongButton from 'common/buttons/longButton';
import LoaderPage from 'common/LoaderPage';
import colors from 'utils/colors';
import { baseFont, boldFont } from 'utils/fonts';
import CalendarComponent from '../common/forms/Calendar';

const Profile = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: ${colors.secondary_blue};
`;

const NA = styled.div`
  min-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  color: ${colors.secondary_blue};
  font-family: ${boldFont};
`;

const Table = styled.table`
  width: 100%;
  margin: 0 auto;
  text-align: left;
`;

const THr = styled.tr`
  text-align: left;
  text-indent: 15px;
  font-family: ${baseFont};
  font-weight: 900;
  color: white;
  &:nth-of-type(odd) {
    background: ${colors.accent1};
  }
`;

const Th = styled.th`
  padding: 10px 0;
`;

const Tr = styled.tr`
  &:hover {
    font-size: 18px;
    color: ${colors.secondary_blue};
    background: ${colors.background_gray};
  }
`;

const Tbody = styled.tbody`
  cursor: pointer;
`;

const Cell = styled.td`
  padding-left: 15px;
`;

const ButtonWrapper = styled.div`
  width: 450px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 0 auto 30px;
`;

const ApproveButton = styled(LongButton)`
  width: 150px;
  font-size: 16px;
  margin: 0 15px;
`;

const DeclineButton = styled(LongButton)`
  width: 150px;
  font-size: 16px;
  margin: 0 15px;
`;

const VacationDiv = styled.div`
  text-align: center;
  color: #21baea;
  font-family: Swiss721BTRegular;
  font-size: 18px;
  ${'' /* to allow room for footer that has absolute positioning */}
  margin-bottom: 100px;
`;

class VacationManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      capacityTab: 'Total',
      currentRequestIdx: 0,
    };
    this.changeTab = this.changeTab.bind(this);
  }

  componentDidMount() {
    const { selectedSystem } = this.props;
    if (localStorage.user_token && selectedSystem) {
      this.props.fetchCounters(selectedSystem.system._id, this.props.history);
      this.props.fetchAllVacationRequests(
        selectedSystem.system._id,
        this.props.history
      );
    }
    if (
      this.props.vacations &&
      this.props.vacations.length > 0 &&
      this.props.match.params &&
      this.props.match.params.vacation_id
    ) {
      _.map(this.props.vacations, (vacation, vacation_index) => {
        if (vacation._id == this.props.match.params.vacation_id) {
          this.setState(
            {
              currentRequestIdx: vacation_index,
            },
            () => {
              this.props.currentUserAnalytics(
                this.props.vacations[vacation_index]
              );
            }
          );
        }
      });
    } else if (this.props.vacations && this.props.vacations.length > 0) {
      this.props.currentUserAnalytics(this.props.vacations[0]);
    }

    this.props.fetchDirectory(this.props.history);
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (
  //     localStorage.user_token &&
  //     !_.isEqual(this.props.vacations, prevProps.vacations) &&
  //     this.props.vacations.length > 0
  //   ) {
  //     this.props.currentUserAnalytics(this.props.vacations[0]);
  //   }
  // }

  changeTab(tab) {
    if (tab !== this.state.capacityTab) {
      this.setState({ capacityTab: tab });
    }
  }

  renderVacationReq() {
    if (this.props.vacations.length > 0) {
      return (
        <Table>
          <thead>
            <THr>
              <Th>Submitted At</Th>
              <Th>Vacation Time</Th>
              <Th>Email</Th>
            </THr>
          </thead>
          <Tbody>
            {_.map(this.props.vacations, (vacation, idx) => {
              return (
                <Tr
                  key={vacation._id}
                  onClick={() => {
                    this.setState({ currentRequestIdx: idx }, () => {
                      this.props.currentUserAnalytics(vacation);
                    });
                  }}
                  style={
                    this.state.currentRequestIdx === idx
                      ? {
                          background: colors.secondary_blue,
                          height: '35px',
                          fontSize: '20px',
                          color: 'white',
                        }
                      : null
                  }
                >
                  <Cell>{moment(vacation.created_at).format('LLL')}</Cell>
                  <Cell>
                    {moment(vacation.hold_from).format('MM/DD/YYYY')} -{' '}
                    {moment(vacation.hold_to).format('MM/DD/YYYY')}
                  </Cell>
                  <Cell>{vacation.email}</Cell>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      );
    } else {
      return <NA>No Vacation Hold Request.</NA>;
    }
  }

  renderAnalytics() {
    const {
      vacationHoldUsed,
      systemCap,
      reservedRequests,
    } = this.props.userAnalytics;
    const vacation = this.props.vacations[this.state.currentRequestIdx];
    const threeMonthOverFiftyPcent = systemCap.filter(
      (system) => system.cap > 0.5
    ).length;
    const sysData = systemCap.filter((system) => system.cap === 0).length;
    let noSysData;
    let reservedPeopleColor;
    let reservedPeopleFontColor;
    let systemCapColor;
    let systemCapAreaColor = colors.secondary_blue;
    let holdDaysColor;
    let holdDaysFontColor;
    {
      /* Setting the maximum var for the red header */
    }
    if (sysData === systemCap.length) {
      noSysData = true;
    }

    if (reservedRequests > 3) {
      reservedPeopleColor = 'warning_red';
      reservedPeopleFontColor = '#083e66';
    }

    if (threeMonthOverFiftyPcent > 15) {
      systemCapColor = 'warning_red';
      systemCapAreaColor = '#083e66';
    }

    if (vacationHoldUsed > 30) {
      holdDaysColor = 'warning_red';
      holdDaysFontColor = '#083e66';
    }
    return (
      <div>
        <Profile>
          <div style={{ margin: '25px auto' }}>
            {/* {vacation.name}: {moment(vacation.hold_from).format('MM/DD/YYYY')} -{' '}
            {moment(vacation.hold_to).format('MM/DD/YYYY')} */}
            Test
          </div>
        </Profile>
        {/* <CardWrapper>
          <AnalyticsCard
            tooltips={true}
            overLimit={reservedPeopleColor === 'warning_red'}
            tooltipsContent="Number of Vacation Hold Requests approved for this locker system during the requested period"
            title={`Approved Hold Requests from ${moment(
              vacation.hold_from
            ).format('MMM Do')} to ${moment(vacation.hold_to).format(
              'MMM Do'
            )}`}
            color={reservedPeopleColor}
            type="vacation"
          >
            <Pluralize
              singular="person"
              plural="people"
              className="pluralize"
              count={reservedRequests}
              style={{ color: '#083e66' }}
            />
          </AnalyticsCard>
          <AnalyticsCard
            tooltips={true}
            overLimit={systemCapColor === 'warning_red'}
            tooltipsContent="A look at the usage trends unique to your locker system users."
            title="System Capacity History"
            color={systemCapColor}
            type="vacation"
          >
            {noSysData ? (
              <NA>No History</NA>
            ) : (
              <AreaChart
                data={systemCap}
                x="time"
                y="cap"
                color={systemCapAreaColor}
              />
            )}
          </AnalyticsCard>

          <AnalyticsCard
            tooltips={true}
            overLimit={holdDaysColor === 'warning_red'}
            tooltipsContent={`Number of Vacation Hold Requests submitted and approved for ${vacation.name} in the last 365 days.`}
            title={`Approved Hold Requests for ${vacation.name} This Year`}
            color={holdDaysColor}
            type="vacation"
          >
            <Pluralize
              style={{ color: '#083e66' }}
              singular="day"
              plural="days"
              className="pluralize"
              count={vacationHoldUsed}
            />
          </AnalyticsCard>
        </CardWrapper> */}
      </div>
    );
  }

  render() {
    const {
      vacations,
      analytics_loading,
      fetchVacationLoading,
      userAnalytics,
      directory,
    } = this.props;

    return (
      <VacationDiv backgroundColor="secondary_blue">
        {/* {vacations && !fetchVacationLoading ? (
          <div style={{ width: '100%' }}>
            {vacations.length > 0 &&
            userAnalytics &&
            !_.isEmpty(userAnalytics) ? (
              <div>
                {this.renderAnalytics()}
                <ButtonWrapper>
                  <ApproveButton
                    textColor="white"
                    backgroundColor="secondary_blue"
                    disabled={analytics_loading ? true : false}
                    onClick={async () => {
                      const idx = this.state.currentRequestIdx;
                      this.setState({ currentRequestIdx: 0 });
                      await this.props.updateVacationHold(
                        idx,
                        'approved',
                        this.props.selectedSystem._id,
                        vacations
                      );
                    }}
                  >
                    Approve
                  </ApproveButton>
                  <DeclineButton
                    textColor="white"
                    backgroundColor="warning_red"
                    disabled={analytics_loading ? true : false}
                    onClick={async () => {
                      const idx = this.state.currentRequestIdx;
                      this.setState({ currentRequestIdx: 0 });
                      await this.props.updateVacationHold(
                        idx,
                        'declined',
                        this.props.selectedSystem._id,
                        vacations
                      );
                    }}
                  >
                    Decline
                  </DeclineButton>
                </ButtonWrapper>
              </div>
            ) : analytics_loading ? (
              <div
                style={{
                  position: 'relative',
                  height: '600px',
                }}
              >
                <LoaderPage color="secondary_blue" height="75%" width="50%" />
              </div>
            ) : null}
            {/* {this.renderVacationReq()} */}
        {/* </div> */}
        {/* ) : (
          <div style={{ marginTop: '200px', height: '200px' }}>
            <LoaderPage color="secondary_blue" height="50%" width="50%" />
          </div> */}
        {/* )} */}
        {this.props.directory.length > 0 && (
          <CalendarComponent sys_id={this.props.directory[0]._id} />
        )}
      </VacationDiv>
    );
  }
}

function mapStateToProps({ admin, auth }) {
  const { directory } = auth;
  const {
    loading,
    selectedSystem,
    vacations,
    userAnalytics,
    currentRequest,
    analytics_loading,
    fetchVacationLoading,
    updateLoading,
  } = admin;
  return {
    directory,
    loading,
    selectedSystem,
    vacations,
    userAnalytics,
    analytics_loading,
    currentRequest,
    fetchVacationLoading,
    updateLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllVacationRequests,
      selectSystem,
      currentUserAnalytics,
      updateVacationHold,
      fetchCounters,
      fetchDirectory,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VacationManagement);
