import React from 'react';
import Tooltip from 'rc-tooltip';
import SvgIcon from 'common/icons/SvgIcon';
import './Panel.css';
import Kiosk from '../../common/icons/Assets/Locker/Kiosk';

const S = 'S';
const M = 'M';
const L = 'L';
const XL = 'XL';
const OVERSIZED = 'OVERSIZED';
const KIOSK = 'KIOSK';

const determineGridRow = (size) => {
  switch (size) {
    case S:
      return 1;
    case M:
      return 2;
    case L:
      return 3;
    case XL:
      return 5;
    case KIOSK:
      return 7;
    default:
      break;
  }
};

const determineBgColor = (available, selected, size) => {
  if (size === KIOSK) {
    return 'white';
  }
  if (available) {
    return 'white';
  } else {
    if (selected) {
      // color is #0A2A3B with 65% opacity, this is done to avoid setting opacity of border color
      return 'rgb(10, 42, 59,.90)';
    } else {
      // color is #009ABF with 65% opacity, this is done to avoid setting opacity of border color
      return 'rgb(0, 154, 191, .65)';
    }
  }
};

const PanelInfo = ({ lockerNumber, startTime, expTime }) => {
  const convertedStartTime = new Date(startTime).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  const convertedExpTime = new Date(expTime).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  return (
    <>
      <h5 className="panel--info--header">Locker Number: {lockerNumber}</h5>
      <h5 className="panel--info--header">Start Time: {convertedStartTime}</h5>
      <h5 className="panel--info--header">
        Expiration Time: {convertedExpTime}
      </h5>
    </>
  );
};

/**
 * Panel determines what type of panel to be displayed in locker
 *
 * @param {*} param0
 */
const Panel = ({
  doorId,
  available,
  panelName,
  size,
  ada,
  startTime,
  expTime,
  selected,
  onClick,
}) => {
  console.log(startTime);
  console.log(expTime);
  return (
    <Tooltip
      id="panel-tooltip"
      placement="bottom"
      trigger={[`${!available && size !== KIOSK && 'click'}`]}
      // rc-tooltip class
      overlayStyle={{
        backgroundColor: '#E4E2E2',
        opacity: 1,
        borderRadius: '18px',
      }}
      overlay={
        !available && (
          <PanelInfo
            lockerNumber={doorId}
            startTime={startTime}
            expTime={expTime}
          />
        )
      }
    >
      <button
        onClick={onClick}
        className="panel"
        style={{
          gridRow: `span ${determineGridRow(size)}`,
          backgroundColor: determineBgColor(available, selected, size),
          ...(size === KIOSK ? { padding: 0 } : {}),
        }}
      >
        {size === KIOSK ? (
          <SvgIcon
            width="100%"
            height="100%"
            icon="lockerKiosk"
            viewBox="0 0 60 100"
          />
        ) : (
          ''
        )}
      </button>
    </Tooltip>
  );
};

export default Panel;
