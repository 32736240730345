import React from 'react';
import './locker_icons.css';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.81 203.01">
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Doors">
        <polygon
          className="locker-icon"
          points="69.45 202.65 0.35 202.65 0.35 0.35 33.65 0.35 33.65 91.15 69.45 91.15 69.45 202.65"
        />
      </g>
    </g>
  </svg>
);
