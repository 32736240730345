import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { logOutUser, onNavToggle } from 'redux/modules/duck-auth';
import { fetchCounters, fetchSystems } from 'redux/modules/duck-admin';
import _ from 'lodash';
import Settings from '../Dashboard/Settings/Settings';
import SettingsForm from '../Dashboard/Settings/SettingsForm';
import Nav from 'common/Nav';
import Actions from './Actions';
import AdminDash from './AdminDash';
import Manage from './Manage';
import Invitation from './Invitation';
import Edit from './Edit';
import VacationManagement from './VacationManagement';
import SystemSettings from './SystemSettings';
import UploadLogo from './UploadLogo';
import SystemLog from './SystemLog';

import Hamburger from 'Dashboard/Hamburger';
import PageShell from 'common/PageShell';
import Notifications from 'common/notification';
import ProfileIcon from 'common/ProfileIcon';
import Footer from 'common/Footer';
import NotificationTables from './NotificationTables';

import { belowfour } from 'utils/media';

const Wrapper = styled.div`
  display: flex;
  min-height: 100%;
  height: auto;
  width: 100%;
  background: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  width: 100%;
  padding: 20px 25px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  height: 35px;
  font-size: 16px;
  margin-right: 15px;
  ${belowfour(css`
    font-size: 14px;
  `)};
`;

class Admin extends Component {
  componentDidMount() {
    if (localStorage.user_token) {
      this.props.fetchSystems(this.props.history);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      localStorage.user_token &&
      !_.isEqual(this.props.selectedSystem, prevProps.selectedSystem) &&
      this.props.history.location.pathname !== '/admin/vacation-management' &&
      this.props.history.location.pathname !== '/admin/actions'
    ) {
      this.props.fetchCounters(
        this.props.selectedSystem.system._id,
        this.props.history
      );
    }
  }

  render() {
    // if mobile, close the side navbar automatically for space
    const {
      history,
      match,
      isAdmin,
      isOwner,
      firstName,
      isNavOpen,
      expired_parcels_counter,
    } = this.props;

    return (
      <Wrapper>
        <Nav
          app="admin"
          isAdmin={isAdmin}
          isOwner={isOwner}
          history={history}
        />
        <Content>
          <Notifications />
          <TopWrapper>
            {isNavOpen ? (
              <Hamburger
                color="base_text2"
                onClick={() => {
                  this.props.onNavToggle();
                }}
              />
            ) : (
              <div />
            )}

            {firstName && firstName.length > 0 ? (
              <ProfileIcon
                firstName={firstName}
                isAdmin={isAdmin}
                mode="admin"
                expired_parcels_counter={expired_parcels_counter}
                logOut={this.props.logOutUser}
              />
            ) : null}
          </TopWrapper>
          <Switch>
            <Route
              exact
              path={`${match.url}/actions`}
              component={PageShell(Actions)}
            />
            <Route
              exact
              path={`${match.url}/system-log`}
              component={PageShell(SystemLog, {
                justifyContent: 'flex-start',
              })}
            />
            <Route
              exact
              path={`${match.url}/dashboard`}
              component={PageShell(AdminDash)}
            />
            <Route
              path={`${match.url}/dashboard/notifications/:type`}
              component={PageShell(NotificationTables)}
            />
            <Route
              exact
              path={`${match.url}/manage`}
              component={PageShell(Manage, { justifyContent: 'flex-start' })}
            />
            <Route
              exact
              path={`${match.url}/manage/:user_id`}
              component={PageShell(Edit)}
            />
            <Route
              exact
              path={`${match.url}/invitation`}
              component={PageShell(Invitation)}
            />
            <Route
              path={`${match.url}/vacation-management/:vacation_id?`}
              component={PageShell(VacationManagement)}
            />
            <Route
              exact
              path={`${match.url}/dashboard/settings`}
              component={PageShell(Settings)}
            />
            <Route
              path={`${match.url}/dashboard/settings/:setting`}
              component={PageShell(SettingsForm)}
            />

            {isOwner ? (
              <Route
                exact
                path={`${match.url}/system_settings`}
                component={PageShell(SystemSettings)}
              />
            ) : null}
            {isOwner ? (
              <Route
                exact
                path={`${match.url}/upload_logo`}
                component={PageShell(UploadLogo)}
              />
            ) : null}
            <Redirect to={`${match.url}/dashboard`} />
          </Switch>
          <Footer isAuthenticated={true} />
        </Content>
      </Wrapper>
    );
  }
}

function mapStateToProps({ admin, auth }) {
  const { isNavOpen } = auth;
  const { selectedSystem, expired_parcels_counter } = admin;

  return { selectedSystem, isNavOpen, expired_parcels_counter };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logOutUser,
      onNavToggle,
      fetchCounters,
      fetchSystems,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
