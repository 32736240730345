import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import SmartInput from 'react-phone-number-input/smart-input';
import Tooltip from 'rc-tooltip';

import 'react-responsive-ui/style.css';
import 'common/styles/tooltip.css';

import Spinner from 'common/Spinner';
import { registerFields } from 'common/forms/formFields';
import renderField from 'common/forms/renderField';
import LongButton from 'common/buttons/longButton.js';
import ErrorMessage from 'common/Error';
import PhoneInput from 'common/PhoneInput';

import colors from 'utils/colors';
import { baseFont } from 'utils/fonts';
import { mobile } from 'utils/media';
import { registerValidate as validate } from 'utils/validations';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${mobile(css`
    flex-direction: column;
  `)};
`;

const Helper = styled(Link)`
  text-decoration: underline;
  color: ${colors.base_text2};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;

  &:hover,
  &:focus {
    opacity: 0.5;
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.5;
  }
  &:visited {
    color: ${colors.base_text2};
  }
`;

const SendButton = styled(LongButton)`
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  height: 55px;
  width: 225px;
  overflow: hidden;
`;

class RegisterForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // add underline animation for phone number inputComponent
    const input = document.querySelector('.rrui__input-field');
    const parent = input.parentNode;
    const wrapper = document.createElement('div');
    wrapper.style.setProperty('width', '100%', 'important');

    parent.replaceChild(wrapper, input);
    wrapper.appendChild(input);
    wrapper.appendChild(document.createElement('span'));
  }

  renderFields() {
    return _.map(registerFields, (field, index) => {
      return (
        <Field
          component={renderField}
          key={field.name}
          type={field.type}
          label={field.label}
          name={field.name}
          placeholder={field.placeholder}
          formtype="register"
        />
      );
    });
  }

  render() {
    const { err, loading, showName = true } = this.props;
    return (
      <FormWrapper
        onSubmit={this.props.handleSubmit(this.props.onRegisterSubmit)}
        autoComplete="off"
      >
        <h1 style={{ color: colors.base_text1, marginTop: 0 }}>REGISTER</h1>
        {this.renderFields(showName)}
        <Row>
          <PhoneInput
            formtype="register"
            inputComponent={SmartInput}
            country="US"
            placeholder="Phone Number (Optional)"
            onChange={(value) => this.props.change('phone', value)}
            style={{
              paddingBottom: '20px',
              width: '100%',
              marginLeft: '25px',
              marginRight: '25px',
            }}
          />
        </Row>
        {err ? (
          <ErrorMessage style={{ margin: '0 auto 25px auto' }}>
            {err.message}
          </ErrorMessage>
        ) : null}
        <SendButton
          textColor="white"
          backgroundColor="base_text2"
          type="submit"
          className="sendBtn"
          style={{ overflow: 'hidden', marginTop: 40 }}
        >
          {loading ? <Loader /> : 'SUBMIT'}
        </SendButton>
        <Helper to="/dashboard/login">Have an account?</Helper>
      </FormWrapper>
    );
  }
}

export default reduxForm({
  validate,
  form: 'registerForm',
  touchOnBlur: false,
})(RegisterForm);
