import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import SmartInput from 'react-phone-number-input/smart-input';
import 'react-responsive-ui/style.css';

import Spinner from 'common/Spinner';
import { registerFields } from 'common/forms/formFields';
import renderField from 'common/forms/renderField';
import LongButton from 'common/buttons/longButton.js';
import ErrorMessage from 'common/Error';

import { baseFont } from 'utils/fonts';
import colors from 'utils/colors';
import { mobile } from 'utils/media';
import { verifyCode as validate } from 'utils/validations';

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 25px 0 50px;
  ${mobile(css`
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    margin-bottom: 0px;
  `)};
`;

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${mobile(css`
    flex-direction: column;
  `)};
`;

const Helper = styled.span`
  color: ${colors.base_text2};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;
  cursor: pointer;

  text-decoration: underline;
`;

const SendButton = styled(LongButton)`
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  height: 55px;
  width: 225px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
`;

const Input = styled.input`
  border: none;
  background: transparent;
  border-radius: 4px;
  border-bottom: 5px solid #cae0ff;
  letter-spacing: 15px;

  box-sizing: border-box;
  color: transparent;
  font-family: ${baseFont};
  font-size: 60px;
  text-align: center;

  color: ${colors.base_text2};

  &:focus {
    outline: none;
  }
`;

class VerifyForm extends Component {
  constructor(props) {
    super(props);

    this.renderInput = this.renderInput.bind(this);
  }

  renderInput(field) {
    const { index } = field;
    return (
      <div>
        <Input {...field.input} type="text" maxLength="6" />
        {field.meta.touched && field.meta.error && (
          <ErrorMessage className="error">{field.meta.error}</ErrorMessage>
        )}
      </div>
    );
  }

  renderFields() {
    return (
      <InputWrapper>
        <Field name="verification_code" component={this.renderInput} />
      </InputWrapper>
    );
  }

  render() {
    const { err, loading } = this.props;

    return (
      <FormWrapper
        onSubmit={this.props.handleSubmit(this.props.onVerifySubmit)}
        autoComplete="off"
      >
        {this.renderFields()}
        {err ? (
          <ErrorMessage style={{ margin: '0 auto 25px auto' }}>
            {err.message}
          </ErrorMessage>
        ) : null}
        <SendButton
          textColor="white"
          backgroundColor="base_text2"
          type="submit"
          style={{ overflow: 'hidden' }}
        >
          {loading ? <Loader /> : 'CONTINUE'}
        </SendButton>
        <Helper onClick={this.props.onResendVerification}>Resend Code?</Helper>
      </FormWrapper>
    );
  }
}

export default reduxForm({
  validate,
  form: 'verifyForm',
  touchOnBlur: false,
})(VerifyForm);
