import React from 'react';
import styled from 'styled-components';

import Dashboard from './IconDashboard';
import PackageHistory from './IconPackageHistory';
import ManageUsers from './IconManage';
import Vacation from './IconVacation';
import HoldRequest from './IconHoldRequest';
import HoldRequestSmall from './IconHoldRequestSmall';
import SystemSettings from './IconSystemSettings';
import Training from './IconTraining';
import Notifications from './IconNotifications';
import ExpiredNotice from './IconExpiredNotice';
import Search from './IconSearch';
import Checkmark from './IconCheckmark';
import Delete from './IconDelete';
import Add from './IconAdd';
import Package from './IconPackage';
import ExpiredPackage from './IconExpiredPackage';
import Camera from './IconCamera';
import Steps from '../registrationsteps/RegistrationSteps';
import Upload from './IconUpload';
import Phone from './IconPhone';
import Email from './IconEmail';
import UnregisteredUser from './IconUnregisteredUser';
import PendingUser from './IconPendingUser';
import Extra_Large from './Assets/Locker/Extra_Large';
import Kiosk from './Assets/Locker/Kiosk';
import Large from './Assets/Locker/Large';
import Medium from './Assets/Locker/Medium';
import Oversize_1 from './Assets/Locker/Oversize_1';
import Oversize from './Assets/Locker/Oversize';
import Small from './Assets/Locker/Small';
import Zlocker_bottom from './Assets/Locker/Zlocker_bottom';
import Zlocker_top from './Assets/Locker/Zlocker_top';

const getPath = (name, fill) => {
  switch (name) {
    case 'dashboard':
      return <Dashboard fill={fill} />;
    case 'packagehistory':
      return <PackageHistory fill={fill} />;
    case 'manageusers':
      return <ManageUsers fill={fill} />;
    case 'vacation-nocircle':
      return <Vacation nocircle={true} fill={fill} />;
    case 'vacation':
      return <Vacation fill={fill} />;
    case 'holdRequest':
      return <HoldRequest fill={fill} />;
    case 'holdRequestSmall':
      return <HoldRequestSmall fill={fill} />;
    case 'holdRequestSmallSpecial':
      return <HoldRequestSmall special={true} fill={fill} />;
    case 'holdRequestSmall-nocircle':
      return <HoldRequestSmall nocircle={true} fill={fill} />;
    case 'systemsettings':
      return <SystemSettings fill={fill} />;
    case 'training':
      return <Training fill={fill} />;
    case 'notifications':
      return <Notifications fill={fill} />;
    case 'expiredNotice':
      return <ExpiredNotice fill={fill} />;
    case 'search':
      return <Search fill={fill} />;
    case 'checkmark':
      return <Checkmark fill={fill} />;
    case 'checkmark-nocircle':
      return <Checkmark fill={fill} nocircle={true} />;
    case 'delete':
      return <Delete fill={fill} />;
    case 'add':
      return <Add fill={fill} />;
    case 'package':
      return <Package fill={fill} />;
    case 'expiredpackage':
      return <ExpiredPackage fill={fill} />;
    case 'packagenocircle':
      return <Package fill={fill} nocircle={true} />;
    case 'camera':
      return <Camera fill={fill} />;
    case 'register':
      return <Steps step="Register" />;
    case 'verify':
      return <Steps step="Verify" />;
    case 'set-pin':
      return <Steps step="Set-Pin" />;
    case 'upload':
      return <Upload fill={fill} />;
    case 'phone':
      return <Phone fill={fill} />;
    case 'email':
      return <Email fill={fill} />;
    case 'unregistered':
      return <UnregisteredUser />;
    case 'pending':
      return <PendingUser />;
    case 'lockerXL':
      return <Extra_Large />;
    case 'lockerL':
      return <Large />;
    case 'lockerM':
      return <Medium />;
    case 'lockerS':
      return <Small />;
    case 'lockerBottom':
      return <Zlocker_bottom />;
    case 'lockerTop':
      return <Zlocker_top />;
    case 'lockerOversize':
      return <Oversize />;
    case 'lockerOversize1':
      return <Oversize_1 />;
    case 'lockerKiosk':
      return <Kiosk />;
    default:
      return <path />;
  }
};

export default ({
  icon = '',
  style = {},
  fill = '#083E66',
  width = '24px',
  className = '',
  height = '24px',
  viewBox = '0 0 24 24',
}) => {
  return (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Style-Guide"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        {getPath(icon, fill)}
      </g>
    </svg>
  );
};
