import React from 'react';
import './locker_icons.css';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.81 112.86">
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Doors">
        <rect
          className="locker-icon"
          x="0.35"
          y="0.35"
          width="78.1"
          height="112.15"
        />
      </g>
    </g>
  </svg>
);
