import { axiosInstance as axios } from 'redux/client';
import { success, warning } from 'react-notification-system-redux';
import {
  inviteSuccess,
  emptyValue,
  settingsSuccess,
  failedEmails,
  failedSecondaryEmails,
  acceptInviteFail,
  addCourierSuccessMsg,
  courierNameExists,
  courierCodeExists,
  invalidCourierCode,
  removeCourier,
  emptyCourier,
  vacationHoldDecision,
  removeUserSuccess,
  addedUsersOnManage,
  failedUsersOnManage,
  resetUserPinSuccessNotif,
} from 'utils/notiOptions';
import FormData from 'form-data';
import { logOutUser } from './duck-auth';
import { initialize } from 'redux-form';
// import moment from 'moment-timezone';

// Action Constants
const RESET_USER_PIN_REQUEST = 'casegoods-PL-web/admin/RESET_USER_PIN_REQUEST';
const RESET_USER_PIN_SUCCESS = 'casegoods-PL-web/admin/RESET_USER_PIN_SUCCESS';
const RESET_USER_PIN_FAIL = 'casegoods-PL-web/admin/RESET_USER_PIN_FAIL';
const CLEAR_PROFILE = 'casegoods-PL-web/admin/CLEAR_PROFILE';
const CLEAR_ERROR = 'casegoods-PL-web/admin/CLEAR_ERROR';
const FETCH_USERS_REQUEST = 'casegoods-PL-web/admin/FETCH_USERS_REQUEST';
const FETCH_USERS_SUCCESS = 'casegoods-PL-web/admin/FETCH_USERS_SUCCESS';
const FETCH_USERS_FAIL = 'casegoods-PL-web/admin/FETCH_USERS_FAIL';
const INVITE_USER_REQUEST = 'casegoods-PL-web/auth/INVITE_USER_REQUEST';
const INVITE_USER_SUCCESS = 'casegoods-PL-web/auth/INVITE_USER_SUCCESS';
const INVITE_USER_FAIL = 'casegoods-PL-web/auth/INVITE_USER_FAIL';
const GENERATE_LINK_REQUEST = 'casegoods-PL-web/auth/GENERATE_LINK_REQUEST';
const GENERATE_LINK_SUCCESS = 'casegoods-PL-web/auth/GENERATE_LINK_SUCCESS';
const GENERATE_LINK_FAIL = 'casegoods-PL-web/auth/GENERATE_LINK_FAIL';
const FETCH_PROFILE_REQUEST = 'casegoods-PL-web/admin/FETCH_PROFILE_REQUEST';
const FETCH_PROFILE_SUCCESS = 'casegoods-PL-web/admin/FETCH_PROFILE_SUCCESS';
const FETCH_PROFILE_FAIL = 'casegoods-PL-web/admin/FETCH_PROFILE_FAIL';
const DELETE_USER_REQUEST = 'casegoods-PL-web/admin/DELETE_USER_REQUEST';
const DELETE_USER_SUCCESS = 'casegoods-PL-web/admin/DELETE_USER_SUCCESS';
const DELETE_USER_FAIL = 'casegoods-PL-web/admin/DELETE_USER_FAIL';
const DEACTIVATE_USER_REQUEST =
  'casegoods-PL-web/admin/DEACTIVATE_USER_REQUEST';
const DEACTIVATE_USER_SUCCESS =
  'casegoods-PL-web/admin/DEACTIVATE_USER_SUCCESS';
const DEACTIVATE_USER_FAIL = 'casegoods-PL-web/admin/DEACTIVATE_USER_FAIL';
const CREATE_USER_REQUEST = 'casegoods-PL-web/admin/CREATE_USER_REQUEST';
const CREATE_USER_SUCCESS = 'casegoods-PL-web/admin/CREATE_USER_SUCCESS';
const CREATE_USER_FAIL = 'casegoods-PL-web/admin/CREATE_USER_FAIL';
const EDIT_USER_REQUEST = 'casegoods-PL-web/admin/EDIT_USER_REQUEST';
const EDIT_USER_SUCCESS = 'casegoods-PL-web/admin/EDIT_USER_SUCCESS';
const EDIT_USER_FAIL = 'casegoods-PL-web/admin/EDIT_USER_FAIL';
const FETCH_SYSTEMS_REQUEST = 'casegoods-PL-web/admin/FETCH_SYSTEMS_REQUEST';
const FETCH_SYSTEMS_SUCCESS = 'casegoods-PL-web/admin/FETCH_SYSTEMS_SUCCESS';
const FETCH_SYSTEMS_FAIL = 'casegoods-PL-web/admin/FETCH_SYSTEMS_FAIL';
const CHANGE_SORT_REQUEST = 'casegoods-PL-web/admin/CHANGE_SORT_REQUEST';
const CHANGE_SORT_SUCCESS = 'casegoods-PL-web/admin/CHANGE_SORT_SUCCESS';
const CHANGE_FILTER_REQUEST = 'casegoods-PL-web/admin/CHANGE_FILTER_REQUEST';
const CHANGE_FILTER_SUCCESS = 'casegoods-PL-web/admin/CHANGE_FILTER_SUCCESS';
const CHANGE_ASCENDING = 'casegoods-PL-web/admin/CHANGE_ASCENDING';
const CHANGE_SYSTEM_SETTINGS_REQ =
  'casegoods-PL-web/admin/CHANGE_SYSTEM_SETTINGS_REQ';
const CHANGE_SYSTEM_SETTINGS_SUCCESS =
  'casegoods-PL-web/admin/CHANGE_SYSTEM_SETTINGS_SUCCESS';
const CHANGE_SYSTEM_SETTINGS_FAIL =
  'casegoods-PL-web/admin/CHANGE_SYSTEM_SETTINGS_FAIL';
const SELECT_SYSTEM_REQUEST = 'casegoods-PL-web/admin/SELECT_SYSTEM_REQ';
const SELECT_SYSTEM_SUCCESS = 'casegoods-PL-web/admin/SELECT_SYSTEM_SUCCESS';
const FETCH_MANAGERS_REQUEST = 'casegoods-PL-web/admin/FETCH_MANAGERS_REQUEST';
const FETCH_MANAGERS_SUCCESS = 'casegoods-PL-web/admin/FETCH_MANAGERS_SUCCESS';
const FETCH_MANAGERS_FAIL = 'casegoods-PL-web/admin/FETCH_MANAGERS_FAIL';
const ADD_COURIER_REQUEST = 'casegoods-PL-web/admin/ADD_COURIER_REQUEST';
const ADD_COURIER_SUCCESS = 'casegoods-PL-web/admin/ADD_COURIER_SUCCESS';
const ADD_COURIER_FAIL = 'casegoods-PL-web/admin/ADD_COURIER_FAIL';
const FETCH_COURIER_REQUEST = 'casegoods-PL-web/admin/FETCH_COURIER_REQUEST';
const FETCH_COURIER_SUCCESS = 'casegoods-PL-web/admin/FETCH_COURIER_SUCCESS';
const FETCH_COURIER_FAIL = 'casegoods-PL-web/admin/FETCH_COURIER_FAIL';
const DELETE_COURIER_REQUEST = 'casegoods-PL-web/admin/DELETE_COURIER_REQUEST';
const DELETE_COURIER_SUCCESS = 'casegoods-PL-web/admin/DELETE_COURIER_SUCCESS';
const DELETE_COURIER_FAIL = 'casegoods-PL-web/admin/DELETE_COURIER_FAIL';
const TRANSFER_OWNERSHIP_REQUEST =
  'casegoods-PL-web/admin/TRANSFER_OWNERSHIP_REQUEST';
const TRANSFER_OWNERSHIP_SUCCESS =
  'casegoods-PL-web/admin/TRANSFER_OWNERSHIP_SUCCESS';
const TRANSFER_OWNERSHIP_FAIL =
  'casegoods-PL-web/admin/TRANSFER_OWNERSHIP_FAIL';
const FETCH_ANALYTICS_REQUEST =
  'casegoods-PL-web/admin/FETCH_ANALYTICS_REQUEST';
const FETCH_ANALYTICS_SUCCESS =
  'casegoods-PL-web/admin/FETCH_ANALYTICS_SUCCESS';
const FETCH_ANALYTICS_FAIL = 'casegoods-PL-web/admin/FETCH_ANALYTICS_FAIL';
const FETCH_VACATION_REQUESTS_REQUEST =
  'casegoods-PL-web/admin/FETCH_VACATION_REQUESTS_REQUEST';
const FETCH_VACATION_REQUESTS_SUCCESS =
  'casegoods-PL-web/admin/FETCH_VACATION_REQUESTS_SUCCESS';
const FETCH_VACATION_REQUESTS_FAIL =
  'casegoods-PL-web/admin/FETCH_VACATION_REQUESTS_FAIL';
const CURRENT_USER_ANALYTICS_REQUEST =
  'casegoods-PL-web/admin/CURRENT_USER_ANALYTICS_REQUEST';
const CURRENT_USER_ANALYTICS_SUCCESS =
  'casegoods-PL-web/admin/CURRENT_USER_ANALYTICS_SUCCESS';
const CURRENT_USER_ANALYTICS_FAIL =
  'casegoods-PL-web/admin/CURRENT_USER_ANALYTICS_FAIL';
const UPDATE_VACATION_HOLD_REQUEST =
  'casegoods-PL-web/admin/UPDATE_VACATION_HOLD_REQUEST';
const UPDATE_VACATION_HOLD_SUCCESS =
  'casegoods-PL-web/admin/UPDATE_VACATION_HOLD_SUCCESS';
const UPDATE_VACATION_HOLD_FAIL =
  'casegoods-PL-web/admin/UPDATE_VACATION_HOLD_FAIL';
const UPDATE_ADDRESS_FIELDS_REQUEST =
  'casegoods-PL-web/admin/UPDATE_ADDRESS_FIELDS_REQUEST';
const UPDATE_ADDRESS_FIELDS_SUCCESS =
  'casegoods-PL-web/admin/UPDATE_ADDRESS_FIELDS_SUCCESS';
const UPDATE_ADDRESS_FIELDS_FAIL =
  'casegoods-PL-web/admin/UPDATE_ADDRESS_FIELDS_FAIL';
const FETCH_COUNTERS_SUCCESS = 'casegoods-PL-web/admin/FETCH_COUNTERS_SUCCESS';
const FETCH_COUNTERS_FAIL = 'casegoods-PL-web/admin/FETCH_COUNTERS_FAIL';
export const FINISHED_ACTION = 'casegoods-PL-web/admin/FINISHED_ACTION';
const FETCH_SYSTEM_LOG_REQUEST =
  'casegoods-PL-web/admin/FETCH_SYSTEM_LOG_REQUEST';
const FETCH_SYSTEM_LOG_SUCCESS =
  'casegoods-PL-web/admin/FETCH_SYSTEM_LOG_SUCCESS';
const FETCH_SYSTEM_LOG_FAIL = 'casegoods-PL-web/admin/FETCH_SYSTEM_LOG_FAIL';
const CHANGE_ROLE_SUCCESS = 'casegoods-PL-web/admin/CHANGE_ROLE_SUCCESS';
const CHANGE_ROLE_FAIL = 'casegoods-PL-web/admin/CHANGE_ROLE_FAIL';
const REMOVE_ACCESS_REQUEST = 'casegoods-PL-web/admin/REMOVE_ACCESS_REQUEST';
const REMOVE_ACCESS_SUCCESS = 'casegoods-PL-web/admin/REMOVE_ACCESS_SUCCESS';
const REMOVE_ACCESS_FAIL = 'casegoods-PL-web/admin/REMOVE_ACCESS_FAIL';
const REMOVE_ACCESS_POP_UP_REQUEST =
  'casegoods-PL-web/admin/REMOVE_ACCESS_POP_UP_REQUEST';
const CLEAR_REMOVE_ACCESS_POP_UP_REQUEST =
  'casegoods-PL-web/admin/CLEAR_REMOVE_ACCESS_POP_UP_REQUEST';
const FETCH_NOTIFICATIONS_LIST_REQUEST =
  'casegoods-PL-web/admin/FETCH_NOTIFICATIONS_LIST_REQUEST';
const FETCH_NOTIFICATIONS_LIST_SUCCESS =
  'casegoods-PL-web/admin/FETCH_NOTIFICATIONS_LIST_SUCCESS';
const FETCH_NOTIFICATIONS_LIST_FAIL =
  'casegoods-PL-web/admin/FETCH_NOTIFICATIONS_LIST_FAIL';
const UNREGISTERED_USERS_REQUEST =
  'casegoods-PL-web/admin/UNREGISTERED_USERS_REQUEST';
const UNREGISTERED_USERS_SUCCESS =
  'casegoods-PL-web/admin/UNREGISTERED_USERS_SUCCESS';
const UNREGISTERED_USERS_FAIL =
  'casegoods-PL-web/admin/UNREGISTERED_USERS_FAIL';
const PENDING_ACCOUNTS_REQUEST =
  'casegoods-PL-web/admin/PENDING_ACCOUNTS_REQUEST';
const PENDING_ACCOUNTS_SUCCESS =
  'casegoods-PL-web/admin/PENDING_ACCOUNTS_SUCCESS';
const PENDING_ACCOUNTS_FAIL = 'casegoods-PL-web/admin/PENDING_ACCOUNTS_FAIL';
const PENDING_USER_ACTION_REQUEST =
  'casegoods-PL-web/admin/PENDING_USER_ACTION_REQUEST';
const APPROVE_PENDING_USER_SUCCESS =
  'casegoods-PL-web/admin/APPROVE_PENDING_USER_SUCCESS';
const APPROVE_PENDING_USER_FAIL =
  'casegoods-PL-web/admin/APPROVE_PENDING_USER_FAIL';
const REJECT_PENDING_USER_SUCCESS =
  'casegoods-PL-web/admin/REJECT_PENDING_USER_SUCCESS';
const REJECT_PENDING_USER_FAIL =
  'casegoods-PL-web/admin/REJECT_PENDING_USER_FAIL';
const FETCH_SYSTEM_MAP_REQUEST =
  'casegoods-PL-web/admin/FETCH_SYSTEM_MAP_REQUEST';
const FETCH_SYSTEM_MAP_SUCCESS =
  'casegoods-PL-web/admin/FETCH_SYSTEM_MAP_SUCCESS';
const FETCH_SYSTEM_MAP_FAIL = 'casegoods-PL-web/admin/FETCH_SYSTEM_MAP_FAIL';

const initialState = {
  loading: false, // Loading boolean for pages / spinners
  error: null,
  genLinkLoading: false,
  genLinkError: false,
  genLink: '',
  removedUser: false,
  users: [],
  couriers: [],
  managers: [],
  systems: [],
  owner_systems: [],
  selectSystemIndex: 0,
  selectedSystem: null,
  profile: null,
  removeUser: '',
  editProfile: null,
  removePopUp: false,
  // Manager users Table
  table_loading: false,
  managers_table_loading: false,
  sortBy: 'email',
  secondSortBy: 'first_name',
  ascending: true,
  filter: '',
  analytics_loading: false,
  transfer_loading: false,
  fetchVacationLoading: false,
  updateLoading: false,
  analytics: null,
  userAnalytics: null,
  currentRequest: null,
  counters: null,
  addressFields: '',
  system_log: null,
  history_loading: false,
  vacations: [],
  unregisteredUsers: [],
  pendingAccounts: [],
  approveUser: false,
  rejectUser: false,
  pendingUserError: false,
  notificationsList: [],
  notificationsListLoading: false,
  notificationsListError: false,
  fetchSystemMapLoading: false,
  fetchSystemMapSuccess: false,
  fetchSystemMapError: false,
  resetUserPinLoading: false,
  resetUserPinSuccess: false,
  resetUserPinError: false,
  lockers: [],
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    case CLEAR_PROFILE:
      return {
        ...state,
        error: null,
        profile: null,
        editProfile: null,
      };
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        managers_table_loading: true,
        error: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        ascending: true,
        managers_table_loading: false,
        users: action.data,
        error: null,
      };
    case FETCH_USERS_FAIL:
      return {
        ...state,
        managers_table_loading: false,
        users: null,
        error: action.error,
      };

    case RESET_USER_PIN_REQUEST:
      return {
        ...state,
        resetUserPinLoading: true,
        resetUserPinSuccess: false,
        resetUserPinError: false,
      };
    case RESET_USER_PIN_SUCCESS:
      return {
        ...state,
        resetUserPinLoading: false,
        resetUserPinSuccess: true,
        resetUserPinError: false,
      };
    case RESET_USER_PIN_FAIL:
      return {
        ...state,
        resetUserPinLoading: false,
        resetUserPinSuccess: false,
        resetUserPinError: action.error,
      };

    case FETCH_SYSTEM_MAP_REQUEST:
      return {
        ...state,
        fetchSystemMapLoading: true,
        fetchSystemMapSuccess: false,
        fetchSystemMapError: false,
      };
    case FETCH_SYSTEM_MAP_SUCCESS:
      return {
        ...state,
        fetchSystemMapLoading: false,
        fetchSystemMapSuccess: true,
        fetchSystemMapError: false,
        lockers: action.data,
      };
    case FETCH_SYSTEM_MAP_FAIL:
      return {
        ...state,
        fetchSystemMapLoading: false,
        fetchSystemMapSuccess: false,
        fetchSystemMapError: action.error,
        lockers: [],
      };

    case INVITE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case INVITE_USER_FAIL:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.error,
      };
    case GENERATE_LINK_REQUEST:
      return {
        ...state,
        genLinkLoading: true,
        error: null,
      };
    case GENERATE_LINK_SUCCESS:
      return {
        ...state,
        genLinkLoading: false,
        genLink: action.link,
        error: null,
      };
    case GENERATE_LINK_FAIL:
      return {
        ...state,
        genLinkLoading: false,
        genLinkError: true,
      };
    case FETCH_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.data,
        error: null,
      };
    case FETCH_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        profile: null,
        error: action.error,
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        table_loading: true,
        error: null,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => {
          return user._id !== action.data.user_id;
        }),
        loading: false,
        table_loading: false,
        profile: null,
        error: null,
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DEACTIVATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: null,
        error: null,
      };
    case DEACTIVATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ADD_COURIER_REQUEST:
      return {
        ...state,
        // loading: true,
        table_loading: true,
        error: null,
      };
    case ADD_COURIER_SUCCESS:
      return {
        ...state,
        // loading: false,
        table_loading: false,
        error: null,
      };
    case ADD_COURIER_FAIL:
      return {
        ...state,
        table_loading: false,
        error: action.error,
      };
    case FETCH_COURIER_REQUEST:
      return {
        ...state,
        table_loading: true,
        error: null,
      };
    case FETCH_COURIER_SUCCESS:
      return {
        ...state,
        ascending: true,
        table_loading: false,
        couriers: action.data,
        error: null,
      };
    case FETCH_COURIER_FAIL:
      return {
        ...state,
        table_loading: false,
        error: action.error,
      };
    case DELETE_COURIER_REQUEST:
      return {
        ...state,
        // loading: true,
        table_loading: true,
        error: null,
      };
    case DELETE_COURIER_SUCCESS:
      return {
        ...state,
        // loading: false,
        table_loading: false,
        error: null,
      };
    case DELETE_COURIER_FAIL:
      return {
        ...state,
        table_loading: false,
        error: action.error,
      };
    case CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: 'created',
        error: null,
      };
    case CREATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case EDIT_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        editProfile: action.data.message,
        error: null,
      };
    case EDIT_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_SYSTEMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SYSTEMS_SUCCESS:
      const systems = action.data;
      const owner_systems = systems.filter((access) => {
        if (access.level == 1) {
          return access;
        }
      });
      return {
        ...state,
        loading: false,
        systems: action.data,
        selectSystemIndex: 0,
        selectedSystem: action.data[0],
        owner_systems,
        error: null,
      };
    case FETCH_SYSTEMS_FAIL:
      return {
        ...state,
        loading: false,
        systems: null,
        error: action.error,
      };
    case FETCH_VACATION_REQUESTS_REQUEST:
      return {
        ...state,
        fetchVacationLoading: true,
        error: null,
      };
    case FETCH_VACATION_REQUESTS_SUCCESS:
      return {
        ...state,
        fetchVacationLoading: false,
        vacations: action.data,
        error: null,
      };
    case FETCH_VACATION_REQUESTS_FAIL:
      return {
        ...state,
        loading: false,
        vacations: [],
        error: action.error,
      };
    case CHANGE_SORT_REQUEST:
      return {
        ...state,
        table_loading: true,
      };
    case CHANGE_SORT_SUCCESS:
      if (action.sortBy === state.sortBy) {
        return {
          ...state,
          table_loading: false,
          ascending: !action.ascending,
        };
      }
      return {
        ...state,
        table_loading: false,
        ascending: true,
        sortBy: action.sortBy,
        secondSortBy: action.secondSortBy,
      };
    case CHANGE_FILTER_REQUEST:
      return {
        ...state,
        table_loading: true,
      };
    case CHANGE_FILTER_SUCCESS:
      return {
        ...state,
        table_loading: false,
        filter: action.input,
      };
    case CHANGE_ASCENDING:
      return {
        ...state,
        ascending: !state.ascending,
      };

    case CHANGE_SYSTEM_SETTINGS_REQ:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_SYSTEM_SETTINGS_SUCCESS:
      const updatedSystem = {
        ...state.systems[action.index],
        system: action.data,
      };

      return {
        ...state,
        loading: false,
        systems: state.systems.map((access) => {
          if (access.system._id === updatedSystem.system._id) {
            return updatedSystem;
          }
          return access;
        }),
        selectedSystem: updatedSystem,
      };
    case CHANGE_SYSTEM_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SELECT_SYSTEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SELECT_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        selectSystemIndex: action.index,
        selectedSystem: state.systems[action.index],
      };

    case FETCH_MANAGERS_REQUEST:
      return {
        ...state,
        transfer_loading: true,
        error: null,
      };
    case FETCH_MANAGERS_SUCCESS:
      return {
        ...state,
        transfer_loading: false,
        managers: action.data,
        error: null,
      };
    case FETCH_MANAGERS_FAIL:
      return {
        ...state,
        transfer_loading: false,
        users: null,
        error: action.error,
      };
    case TRANSFER_OWNERSHIP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TRANSFER_OWNERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TRANSFER_OWNERSHIP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case FETCH_ANALYTICS_REQUEST:
      return {
        ...state,
        analytics_loading: true,
        error: null,
      };
    case FETCH_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics_loading: false,
        analytics: { ...state.analytics, ...action.data },
        error: null,
      };
    case FETCH_ANALYTICS_FAIL:
      return {
        ...state,
        analytics_loading: false,
        analytics: null,
        error: action.error,
      };
    case FETCH_NOTIFICATIONS_LIST_REQUEST:
      return {
        ...state,
        notificationsListLoading: true,
        notificationsListError: false,
      };
    case FETCH_NOTIFICATIONS_LIST_SUCCESS:
      return {
        ...state,
        notificationsListLoading: false,
        notificationsList: action.data,
        notificationsListError: null,
      };
    case FETCH_NOTIFICATIONS_LIST_FAIL:
      return {
        ...state,
        notificationsListLoading: false,
        notificationsList: [],
        notificationsListError: action.error,
      };

    case UNREGISTERED_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UNREGISTERED_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        unregisteredUsers: action.data,
        error: null,
      };
    case UNREGISTERED_USERS_FAIL:
      return {
        ...state,
        loading: false,
        unregisteredUsers: null,
        error: action.error,
      };
    case PENDING_ACCOUNTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PENDING_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingAccounts: action.data,
        error: null,
      };
    case PENDING_ACCOUNTS_FAIL:
      return {
        ...state,
        loading: false,
        pendingAccounts: null,
        error: action.error,
      };
    case PENDING_USER_ACTION_REQUEST:
      return {
        ...state,
        approveUser: false,
        rejectUser: false,
        pendingUserError: false,
      };
    case APPROVE_PENDING_USER_SUCCESS:
      return {
        ...state,
        approveUser: true,
      };
    case APPROVE_PENDING_USER_FAIL:
      return {
        ...state,
        pendingUserError: true,
      };
    case REJECT_PENDING_USER_SUCCESS:
      return {
        ...state,
        rejectUser: true,
      };
    case REJECT_PENDING_USER_FAIL:
      return {
        ...state,
        pendingUserError: true,
      };

    case CURRENT_USER_ANALYTICS_REQUEST:
      return {
        ...state,
        analytics_loading: true,
        userAnalytics: null,
        error: null,
      };
    case CURRENT_USER_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics_loading: false,
        userAnalytics: action.data,
        error: null,
      };
    case CURRENT_USER_ANALYTICS_FAIL:
      return {
        ...state,
        analytics_loading: false,
        userAnalytics: null,
        error: action.error,
      };

    case UPDATE_VACATION_HOLD_REQUEST:
      return {
        ...state,
        updateLoading: true,
        error: null,
      };
    case UPDATE_VACATION_HOLD_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        vacations: action.data.vacations,
        userAnalytics: action.data.userAnalytics,
        counters: {
          ...state.counters,
          vacation_count: state.counters.vacation_count - 1,
          action_count: state.counters.action_count - 1,
        },
        error: null,
      };
    case UPDATE_VACATION_HOLD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_ADDRESS_FIELDS_REQUEST:
      return {
        ...state,
        addressFields: '',
        error: null,
      };
    case UPDATE_ADDRESS_FIELDS_SUCCESS:
      return {
        ...state,
        addressFields: action.data,
        error: null,
      };
    case UPDATE_ADDRESS_FIELDS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case FETCH_COUNTERS_SUCCESS:
      return {
        ...state,
        counters: action.data,
        expired_parcels_counter: action.data.expired_count,
      };
    case FETCH_COUNTERS_FAIL:
      return {
        ...state,
        counters: null,
        expired_parcels_counter: null,
        error: action.error,
      };
    case FINISHED_ACTION:
      return {
        ...state,
        counters: {
          ...state.counters,
          action_count: state.counters.action_count - 1,
        },
      };

    case FETCH_SYSTEM_LOG_REQUEST:
      return {
        ...state,
        history_loading: true,
        error: null,
      };
    case FETCH_SYSTEM_LOG_SUCCESS:
      return {
        ...state,
        history_loading: false,
        system_log: action.data,
        error: null,
      };
    case FETCH_SYSTEM_LOG_FAIL:
      return {
        ...state,
        history_loading: false,
        system_log: null,
        error: action.error,
      };

    case CHANGE_ROLE_SUCCESS:
      return {
        ...state,
        users: state.users.map((user, index) => {
          if (user._id == action.data._user._id) {
            return {
              ...user,
              user_role: action.data.user_role,
            };
          }
          return user;
        }),
        error: null,
      };
    case CHANGE_ROLE_FAIL:
      return {
        ...state,
        error: action.error,
      };

    case REMOVE_ACCESS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case REMOVE_ACCESS_SUCCESS:
      // remove user from table
      const new_users = state.users.filter((user) => user._id !== action.data);

      return {
        ...state,
        users: new_users,
        removedUser: true,
        error: null,
      };
    case REMOVE_ACCESS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case REMOVE_ACCESS_POP_UP_REQUEST:
      return {
        ...state,
        removePopUp: true,
        removeUser: action.data,
      };
    case CLEAR_REMOVE_ACCESS_POP_UP_REQUEST:
      return {
        ...state,
        removePopUp: false,
      };

    default:
      return state;
  }
}

// Actions
export function clearProfileReq() {
  return {
    type: CLEAR_PROFILE,
  };
}

export function clearErrorReq() {
  return {
    type: CLEAR_ERROR,
  };
}

export function fetchUsersReq() {
  return {
    type: FETCH_USERS_REQUEST,
  };
}

export function fetchUsersSuccess(data) {
  return {
    type: FETCH_USERS_SUCCESS,
    data,
  };
}

export function fetchUsersFail(error) {
  return {
    type: FETCH_USERS_FAIL,
    error,
  };
}

export function resetUserPinReq() {
  return {
    type: RESET_USER_PIN_REQUEST,
  };
}

export function resetUserPinSuccess(data) {
  return {
    type: RESET_USER_PIN_SUCCESS,
    data,
  };
}

export function resetUserPinFail(error) {
  return {
    type: RESET_USER_PIN_FAIL,
    error,
  };
}

export function fetchSystemMapReq() {
  return {
    type: FETCH_SYSTEM_MAP_REQUEST,
  };
}

export function fetchSystemMapSuccess(data) {
  return {
    type: FETCH_SYSTEM_MAP_SUCCESS,
    data,
  };
}

export function fetchSystemMapFail(error) {
  return {
    type: FETCH_SYSTEM_MAP_FAIL,
    error,
  };
}

export function fetchSystemsReq() {
  return {
    type: FETCH_SYSTEMS_REQUEST,
  };
}

export function fetchSystemsSuccess(data) {
  return {
    type: FETCH_SYSTEMS_SUCCESS,
    data,
  };
}

export function fetchSystemsFail(error) {
  return {
    type: FETCH_SYSTEMS_FAIL,
    error,
  };
}

export function inviteUserReq() {
  return {
    type: INVITE_USER_REQUEST,
  };
}

export function inviteUserSuccess() {
  return {
    type: INVITE_USER_SUCCESS,
  };
}

export function inviteUserFail(error) {
  return {
    type: INVITE_USER_FAIL,
    error,
  };
}
export function generateLinkReq() {
  return {
    type: GENERATE_LINK_REQUEST,
  };
}

export function generateLinkSuccess(link) {
  return {
    type: GENERATE_LINK_SUCCESS,
    link,
  };
}

export function generateLinkFail(error) {
  return {
    type: GENERATE_LINK_FAIL,
    error,
  };
}

export function fetchProfileReq() {
  return {
    type: FETCH_PROFILE_REQUEST,
  };
}

export function fetchProfileSuccess(data) {
  return {
    type: FETCH_PROFILE_SUCCESS,
    data,
  };
}

export function fetchProfileFail(error) {
  return {
    type: FETCH_PROFILE_FAIL,
    error,
  };
}

export function deleteUserReq() {
  return {
    type: DELETE_USER_REQUEST,
  };
}

export function deleteUserSuccess(user_id) {
  return {
    type: DELETE_USER_SUCCESS,
    data: { user_id },
  };
}

export function deleteUserFail(error) {
  return {
    type: DELETE_USER_FAIL,
    error,
  };
}

export function deactivateUserReq() {
  return {
    type: DEACTIVATE_USER_REQUEST,
  };
}

export function deactivateUserSuccess() {
  return {
    type: DEACTIVATE_USER_SUCCESS,
  };
}

export function deactivateUserFail(error) {
  return {
    type: DEACTIVATE_USER_FAIL,
    error,
  };
}
export function notificationsListReq() {
  return {
    type: FETCH_NOTIFICATIONS_LIST_REQUEST,
  };
}

export function notificationsListSuccess(data) {
  return {
    type: FETCH_NOTIFICATIONS_LIST_SUCCESS,
    data,
  };
}

export function notificationsListFail(error) {
  return {
    type: FETCH_NOTIFICATIONS_LIST_FAIL,
    error,
  };
}

export function unregisteredUsersReq() {
  return {
    type: UNREGISTERED_USERS_REQUEST,
  };
}

export function unregisteredUsersSuccess(data) {
  return {
    type: UNREGISTERED_USERS_SUCCESS,
    data,
  };
}

export function unregisteredUsersFail(error) {
  return {
    type: UNREGISTERED_USERS_FAIL,
    error,
  };
}
export function pendingUsersReq() {
  return {
    type: PENDING_ACCOUNTS_REQUEST,
  };
}

export function pendingUsersSuccess(data) {
  return {
    type: PENDING_ACCOUNTS_SUCCESS,
    data,
  };
}

export function pendingUsersFail(error) {
  return {
    type: PENDING_ACCOUNTS_FAIL,
    error,
  };
}
export function pendingUserActionReq() {
  return {
    type: PENDING_USER_ACTION_REQUEST,
  };
}

export function approvePendingUserSuccess() {
  return {
    type: APPROVE_PENDING_USER_SUCCESS,
  };
}

export function approvePendingUserFail() {
  return {
    type: APPROVE_PENDING_USER_FAIL,
  };
}

export function rejectPendingUserSuccess() {
  return {
    type: REJECT_PENDING_USER_SUCCESS,
  };
}

export function rejectPendingUserFail() {
  return {
    type: REJECT_PENDING_USER_FAIL,
  };
}

export function addCourierReq() {
  return {
    type: ADD_COURIER_REQUEST,
  };
}

export function addCourierSuccess() {
  return {
    type: ADD_COURIER_SUCCESS,
  };
}

export function addCourierFail(error) {
  return {
    type: ADD_COURIER_FAIL,
    error,
  };
}

export function createUserReq() {
  return {
    type: CREATE_USER_REQUEST,
  };
}

export function createUserSuccess(data) {
  return {
    type: CREATE_USER_SUCCESS,
    data,
  };
}

export function createUserFail(error) {
  return {
    type: CREATE_USER_FAIL,
    error,
  };
}

export function editUserReq() {
  return {
    type: EDIT_USER_REQUEST,
  };
}

export function editUserSuccess(data) {
  return {
    type: EDIT_USER_SUCCESS,
    data,
  };
}

export function editUserFail(error) {
  return {
    type: EDIT_USER_FAIL,
    error,
  };
}

export function changeSortReq() {
  return {
    type: CHANGE_SORT_REQUEST,
  };
}

export function changeSortSuccess(sortBy, secondSortBy, ascending) {
  return {
    type: CHANGE_SORT_SUCCESS,
    sortBy,
    secondSortBy,
    ascending,
  };
}

export function changeFilterReq() {
  return {
    type: CHANGE_FILTER_REQUEST,
  };
}

export function changeFilterSuccess(input) {
  return {
    type: CHANGE_FILTER_SUCCESS,
    input,
  };
}

export function changeAscendingReq() {
  return {
    type: CHANGE_ASCENDING,
  };
}

export function changeSystemSettingsReq() {
  return {
    type: CHANGE_SYSTEM_SETTINGS_REQ,
  };
}

export function changeSystemSettingsSuccess(data, index) {
  return {
    type: CHANGE_SYSTEM_SETTINGS_SUCCESS,
    data,
    index,
  };
}

export function changeSystemSettingsFail(error) {
  return {
    type: CHANGE_SYSTEM_SETTINGS_FAIL,
    error,
  };
}

export function selectSystemReq() {
  return {
    type: SELECT_SYSTEM_REQUEST,
  };
}

export function selectSystemSuccess(index) {
  return {
    type: SELECT_SYSTEM_SUCCESS,
    index,
  };
}

export function fetchManagersReq() {
  return {
    type: FETCH_MANAGERS_REQUEST,
  };
}

export function fetchManagersSuccess(data) {
  return {
    type: FETCH_MANAGERS_SUCCESS,
    data,
  };
}

export function fetchManagersFail(error) {
  return {
    type: FETCH_MANAGERS_FAIL,
    error,
  };
}

export function transferOwnershipReq() {
  return {
    type: TRANSFER_OWNERSHIP_REQUEST,
  };
}

export function transferOwnershipSuccess() {
  return {
    type: TRANSFER_OWNERSHIP_SUCCESS,
  };
}

export function transferOwnershipFail(error) {
  return {
    type: TRANSFER_OWNERSHIP_FAIL,
    error,
  };
}

export function fetchAnalyticsReq() {
  return {
    type: FETCH_ANALYTICS_REQUEST,
  };
}

export function fetchAnalyticsSuccess(data) {
  return {
    type: FETCH_ANALYTICS_SUCCESS,
    data,
  };
}

export function fetchAnalyticsFail(error) {
  return {
    type: FETCH_ANALYTICS_FAIL,
    error,
  };
}

export function fetchCourierReq() {
  return {
    type: FETCH_COURIER_REQUEST,
  };
}

export function fetchCourierSuccess(data) {
  return {
    type: FETCH_COURIER_SUCCESS,
    data,
  };
}

export function fetchCourierFail(error) {
  return {
    type: FETCH_COURIER_FAIL,
    error,
  };
}

export function deleteCourierReq() {
  return {
    type: DELETE_COURIER_REQUEST,
  };
}

export function deleteCourierSuccess(data) {
  return {
    type: DELETE_COURIER_SUCCESS,
    data,
  };
}

export function deleteCourierFail(error) {
  return {
    type: DELETE_COURIER_FAIL,
    error,
  };
}

export function fetchVacationRequestsReq() {
  return {
    type: FETCH_VACATION_REQUESTS_REQUEST,
  };
}

export function fetchVacationRequestsSuccess(data) {
  return {
    type: FETCH_VACATION_REQUESTS_SUCCESS,
    data,
  };
}

export function fetchVacationRequestsFail(error) {
  return {
    type: FETCH_VACATION_REQUESTS_FAIL,
    error,
  };
}

export function currentUserAnalyticsReq() {
  return {
    type: CURRENT_USER_ANALYTICS_REQUEST,
  };
}

export function currentUserAnalyticsSuccess(data) {
  return {
    type: CURRENT_USER_ANALYTICS_SUCCESS,
    data,
  };
}

export function currentUserAnalyticsFail(error) {
  return {
    type: CURRENT_USER_ANALYTICS_FAIL,
    error,
  };
}

export function updateVacationHoldReq() {
  return {
    type: UPDATE_VACATION_HOLD_REQUEST,
  };
}

export function updateVacationHoldSuccess(data) {
  return {
    type: UPDATE_VACATION_HOLD_SUCCESS,
    data,
  };
}

export function updateVacationHoldFail(error) {
  return {
    type: UPDATE_VACATION_HOLD_FAIL,
    error,
  };
}

export function updateAddressFieldsReq() {
  return {
    type: UPDATE_ADDRESS_FIELDS_REQUEST,
  };
}

export function updateAddressFieldsSuccess(data) {
  return {
    type: UPDATE_ADDRESS_FIELDS_SUCCESS,
    data,
  };
}

export function updateAddressFieldsFail(error) {
  return {
    type: UPDATE_ADDRESS_FIELDS_FAIL,
    error,
  };
}

export function fetchCountersSuccess(data) {
  return {
    type: FETCH_COUNTERS_SUCCESS,
    data,
  };
}

export function fetchCountersFail(error) {
  return {
    type: FETCH_COUNTERS_FAIL,
    error,
  };
}

export function fetchSystemLogReq() {
  return {
    type: FETCH_SYSTEM_LOG_REQUEST,
  };
}

export function fetchSystemLogSuccess(data) {
  return {
    type: FETCH_SYSTEM_LOG_SUCCESS,
    data,
  };
}

export function fetchSystemLogFail(error) {
  return {
    type: FETCH_SYSTEM_LOG_FAIL,
    error,
  };
}

export function changeRoleSuccess(data) {
  return {
    type: CHANGE_ROLE_SUCCESS,
    data,
  };
}

export function changeRoleFail(error) {
  return {
    type: CHANGE_ROLE_FAIL,
    error,
  };
}

export function removeAccessReq() {
  return {
    type: REMOVE_ACCESS_REQUEST,
  };
}

export function removeAccessSuccess(data) {
  return {
    type: REMOVE_ACCESS_SUCCESS,
    data,
  };
}

export function removeAccessFail(error) {
  return {
    type: REMOVE_ACCESS_FAIL,
    error,
  };
}

export function removeAccessPopUpReq(data) {
  return {
    type: REMOVE_ACCESS_POP_UP_REQUEST,
    data,
  };
}

export function clearRemoveAccessPopUpReq(data) {
  return {
    type: CLEAR_REMOVE_ACCESS_POP_UP_REQUEST,
    data,
  };
}

// Action Creators
export const clearProfile = () => (dispatch) => {
  dispatch(clearProfileReq());
};

export const clearError = () => (dispatch) => {
  dispatch(clearErrorReq());
};

export const fetchUsers = (system_id, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchUsersFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchUsersReq());
  axios
    .get(`/admin/fetchUsers/${system_id}`)
    .then(({ data }) => {
      dispatch(fetchUsersSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(fetchUsersFail(err.response.data));
    });
};

export const fetchSystemMap = () => (dispatch, getState) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchSystemMapFail());
    return;
  }
  const system_id = getState().admin.selectedSystem.system._id;

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchSystemMapReq());
  axios
    .get(`/admin/map/${system_id}`)
    .then(({ data }) => {
      dispatch(fetchSystemMapSuccess(data));
    })
    .catch((err) => {
      dispatch(fetchSystemMapFail(err.response.data));
    });
};

export const inviteUser = (values, resetForm) => (dispatch) => {
  if (values.emails.length == 0) {
    return dispatch(warning(emptyValue));
  }

  dispatch(inviteUserReq());
  const invitationInfo = {
    emails: values.emails,
    system_id: values.system_id,
  };

  axios
    .post('/users/user/invite', invitationInfo)
    .then(({ data }) => {
      dispatch(success(inviteSuccess));
      if (data.failedEmails && data.failedEmails.length > 0) {
        dispatch(warning(failedEmails(data.failedEmails)));
      }
      if (data.failedSecondaryEmails && data.failedSecondaryEmails.length > 0) {
        dispatch(warning(failedSecondaryEmails(data.failedSecondaryEmails)));
      }

      dispatch(inviteUserSuccess());
      dispatch(resetForm());
    })
    .catch((err) => {
      dispatch(inviteUserFail(err));
    });
};

export const generateLink = (sysInfo, resetForm) => (dispatch) => {
  dispatch(generateLinkReq());

  axios
    .post('/admin/link', sysInfo)
    .then(({ data }) => {
      dispatch(generateLinkSuccess(data));
      dispatch(resetForm());
    })
    .catch((err) => {
      dispatch(generateLinkFail(err));
    });
};

export const fetchProfile = (user_id, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchProfileFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchProfileReq());
  axios
    .get(`/admin/fetchProfile/${user_id}`)
    .then(({ data }) => {
      dispatch(fetchProfileSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(fetchProfileFail(err.response.data));
    });
};

export const deleteUser = (user_id, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(deleteUserFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(deleteUserReq());
  axios
    .delete(`/admin/deleteUser/${user_id}`)
    .then(({ data }) => {
      dispatch(deleteUserSuccess(user_id));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(deleteUserFail(err.response.data));
    });
};

export const fetchNotificationsList = (sys_id) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(notificationsListFail(true));
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(notificationsListReq());
  // axios GET does not allow a body to be sent so use of POST here
  axios
    .post(`/admin/notificationslist`, { system_id: sys_id })
    .then(({ data }) => {
      dispatch(notificationsListSuccess(data));
    })
    .catch((err) => {
      dispatch(notificationsListFail(err.response.data));
    });
};

export const fetchUnregisteredUsers = () => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(unregisteredUsersFail(true));
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(unregisteredUsersReq());
  axios
    .get(`/admin/unregistered`)
    .then(({ data }) => {
      dispatch(unregisteredUsersSuccess(data));
    })
    .catch(() => {
      dispatch(unregisteredUsersFail(true));
    });
};

export const fetchPendingUsers = () => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(pendingUsersFail(true));
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(pendingUsersReq());
  axios
    .get(`/admin/pending`)
    .then(({ data }) => {
      dispatch(pendingUsersSuccess(data));
    })
    .catch((err) => {
      dispatch(pendingUsersFail(err.response.data));
    });
};

export const approvePendingUser = (id, system_id) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(pendingUsersFail(true));
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(pendingUserActionReq());
  axios
    .put(`/admin/pending`, { id, system_id })
    .then(({ data }) => {
      dispatch(approvePendingUserSuccess());
    })
    .catch((err) => {
      dispatch(approvePendingUserFail(err.response.data));
    });
};

export const rejectPendingUser = (id) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(pendingUsersFail(true));
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(pendingUserActionReq());
  axios
    .delete(`/admin/pending`, { id })
    .then(({ data }) => {
      dispatch(rejectPendingUserSuccess());
    })
    .catch((err) => {
      dispatch(rejectPendingUserFail(err.response.data));
    });
};

export const editUser = (user, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(editUserFail());
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(editUserReq());
  axios
    .post('/users/user/edit', user)
    .then(({ data }) => {
      dispatch(success(settingsSuccess));
      dispatch(editUserSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(editUserFail(err.response.data.message));
    });
};

export const createUser = (data, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(createUserFail());
    return;
  }

  const { users } = data;

  if (users.length === 1) {
    if (!users[0].first_name || !users[0].last_name || !users[0].employee_id) {
      dispatch(
        createUserFail(
          'First Name, Last Name and Login are required to create a user'
        )
      );
      return;
    }
    if (users[0].role == '1' && !users[0].email) {
      dispatch(createUserFail('Email is required for Admins'));
      return;
    }
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(createUserReq());

  axios
    .post('/users/user/invite', data)
    .then(({ data }) => {
      const successfulUsersAdded = users.length - data.failedUsers.length;
      dispatch(success(addedUsersOnManage(successfulUsersAdded)));
      if (data.failedUsers && data.failedUsers.length > 0) {
        dispatch(warning(failedUsersOnManage(data.failedUsers)));
      }
      dispatch(createUserSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }

      dispatch(createUserFail(err.response.data.message));
    });
};

export const fetchSystems = (history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchSystemsFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchSystemsReq());
  axios
    .get('/admin/fetchSystems/')
    .then(({ data }) => {
      dispatch(fetchSystemsSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(fetchSystemsFail(err.response.data));
    });
};

export const changeSort = (sortBy, secondSortBy, ascending) => (dispatch) => {
  dispatch(changeSortReq());
  dispatch(changeSortSuccess(sortBy, secondSortBy, ascending));
};

export const changeFilter = (input) => (dispatch) => {
  dispatch(changeFilterReq());
  dispatch(changeFilterSuccess(input));
};

export const changeAscending = () => (dispatch) => {
  dispatch(changeAscendingReq());
};

export const changeSystemSettings = (settings, index, history) => (
  dispatch
) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(changeSystemSettingsFail());
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(changeSystemSettingsReq());
  dispatch(initialize('systemSettingsForm')); // Reset form attributes
  axios
    .post('/admin/changeSystemSettings', settings)
    .then(({ data }) => {
      dispatch(success(settingsSuccess));
      dispatch(changeSystemSettingsSuccess(data, index));
      return history.push('/admin/system_settings');
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(changeSystemSettingsFail(err.response.data));
    });
};

export const selectSystem = (index) => (dispatch) => {
  dispatch(selectSystemReq());
  dispatch(selectSystemSuccess(index));
};

export const fetchManagers = (system_id, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchManagersFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchManagersReq());
  axios
    .get(`/admin/fetchManagers/${system_id}`)
    .then(({ data }) => {
      dispatch(fetchManagersSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(fetchManagersFail(err.response.data));
    });
};

export const transferOwnership = (system_id, selectedManager, history) => (
  dispatch
) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(transferOwnershipFail());
    return;
  }

  const values = { system_id, selectedManager };

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(transferOwnershipReq());
  axios
    .post('/admin/transferOwnership', values)
    .then(({ data }) => {
      dispatch(transferOwnershipSuccess());
      window.location.reload();
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(transferOwnershipFail(err.response.data));
    });
};

export const fetchAnalytics = (system_id, timezone, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchAnalyticsFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchAnalyticsReq());
  axios
    .get(`/admin/fetchAnalytics/${system_id}&${timezone}`)
    .then(({ data }) => {
      dispatch(fetchAnalyticsSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(fetchAnalyticsFail(err.response.data));
    });
};

export const acceptInvite = (invite_token, history) => (dispatch) => {
  delete axios.defaults.headers.common.Authorization;
  axios
    .post(`/admin/accept_invite/`, { invite_token })
    .then(({ data }) => {
      if (data.success) {
        history.push('/');
        dispatch(success(inviteSuccess));
      } else {
        history.push('/');
        dispatch(warning(acceptInviteFail));
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
    });
};

export const resetUserPin = (system_id, employee_id) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(resetUserPinFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(resetUserPinReq());
  axios
    .post(`/admin/reset_user_pin/${system_id}`, {
      employee_id,
    })
    .then(({ data }) => {
      dispatch(success(resetUserPinSuccessNotif));
      dispatch(resetUserPinSuccess(data));
    })
    .catch((err) => {
      dispatch(resetUserPinFail(err.response.data));
    });
};

export const deactivateUser = (user_id, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(deactivateUserFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(deactivateUserReq());
  axios
    .post(`/admin/deactivateUser/${user_id}`)
    .then(() => {
      dispatch(deactivateUserSuccess());
      history.push('/admin/manage');
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(deactivateUserFail(err.response.data));
    });
};

export const courierWarning = (type) => (dispatch) => {
  if (type === 'emptyCourier') {
    return dispatch(warning(emptyCourier));
  }
  if (type === 'invalidCourierCode') {
    return dispatch(warning(invalidCourierCode));
  }
};

export const addCourier = (system, name, code, undo = false, history) => (
  dispatch
) => {
  dispatch(addCourierReq());
  let courierInfo = new FormData();
  courierInfo.append('_system', system);
  courierInfo.append('name', name);
  courierInfo.append('code', code);

  const token = localStorage.user_token;
  if (!token) {
    dispatch(addCourierFail());
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  axios
    .post('/admin/couriers', courierInfo)
    .then(({ data }) => {
      dispatch(addCourierSuccess());

      if (data.warn && data.warn === 'Courier already exists') {
        courierNameExists.message = `Name: ${capitalizer(
          data.courier.name
        )}, Code: ${data.courier.code}`;

        dispatch(warning(courierNameExists));
      } else if (
        data.warn &&
        data.warn === 'This code has already been assigned to another courier.'
      ) {
        dispatch(warning(courierCodeExists));
      } else if (!undo) {
        dispatch(success(addCourierSuccessMsg));
      }

      dispatch(fetchCouriers(system));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(addCourierFail(err.response ? err.response.data : err));
    });
};

const capitalizer = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const fetchCouriers = (system_id, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchCourierFail());
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchCourierReq());

  axios
    .get(`/admin/couriers/${system_id}`)
    .then(({ data }) => {
      dispatch(fetchCourierSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(fetchCourierFail(err.response.data));
    });
};

export const deleteCourier = (courier_id, system, name, code, history) => (
  dispatch
) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(deleteCourierFail());
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(deleteCourierReq());

  axios
    .delete(`/admin/couriers/${courier_id}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(deleteCourierSuccess(data));
        dispatch(fetchCouriers(system));
        dispatch(success(removeCourier(system, name, code)));
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(deleteCourierFail(err.response ? err.response.data : err));
    });
};

export const fetchVacationRequests = (sys_id, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchVacationRequestsFail());
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchVacationRequestsReq());

  axios
    .get(`/admin/vacation-hold/${sys_id}`)
    .then(({ data }) => {
      dispatch(fetchVacationRequestsSuccess(data));
    })
    .catch((err) => {
      dispatch(
        fetchVacationRequestsFail(err.response ? err.response.data : err)
      );
    });
};

export const fetchAllVacationRequests = (sys_id, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchVacationRequestsFail());
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchVacationRequestsReq());

  axios
    .get(`/admin/vacation-hold-all/${sys_id}`)
    .then(({ data }) => {
      dispatch(fetchVacationRequestsSuccess(data));
    })
    .catch((err) => {
      dispatch(
        fetchVacationRequestsFail(err.response ? err.response.data : err)
      );
    });
};

export const currentUserAnalytics = (vacationRequest) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(currentUserAnalyticsFail());
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(currentUserAnalyticsReq());

  axios
    .post('/admin/user-analytics', { vacationRequest })
    .then(({ data }) => {
      dispatch(currentUserAnalyticsSuccess(data));
    })
    .catch((err) => {
      dispatch(
        currentUserAnalyticsFail(err.response ? err.response.data : err)
      );
    });
};

export const updateVacationHold = (
  index,
  decision,
  sys_id,
  oldVacations,
  history
) => (dispatch) => {
  const token = localStorage.user_token;
  const request_id = oldVacations[index]._id;
  let newVacations = [...oldVacations].filter((_, idx) => idx !== index);

  if (!token) {
    dispatch(updateVacationHoldFail());
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(updateVacationHoldReq());

  axios
    .put('/admin/vacation-hold-all', { request_id, decision })
    .then((res) => {
      if (res.status === 201) {
        dispatch(
          updateVacationHoldSuccess({
            sys_id,
            vacations: newVacations,
            userAnalytics: res.data,
          })
        );

        dispatch(success(vacationHoldDecision));
      }
    })
    .catch((err) => {
      dispatch(updateVacationHoldFail(err.response ? err.response.data : err));
    });
};

export const fetchCounters = (sys_id, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchCountersFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios
    .get(`/admin/counters/${sys_id}`)
    .then(({ data }) => {
      dispatch(fetchCountersSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        history.push('/dashboard/login');
      }
      dispatch(fetchCountersFail(err.response.data || err));
    });
};

export const updateAddressFields = (geocodedAddress, originalAddress1) => (
  dispatch
) => {
  dispatch(updateAddressFieldsReq());

  try {
    const addressComponents = geocodedAddress.address_components;
    let address1, city, state, zipcode;
    addressComponents.forEach((addressComponent) => {
      if (addressComponent.types[0] === 'street_number') {
        address1 = `${addressComponent.long_name} `;
      }

      if (!address1 && originalAddress1) {
        address1 = originalAddress1;
      } else if (addressComponent.types[0] === 'route') {
        address1 += addressComponent.long_name;
      }

      if (addressComponent.types[0] === 'locality') {
        city = addressComponent.long_name;
      }

      if (addressComponent.types[0] === 'administrative_area_level_1') {
        state = addressComponent.short_name;
      }

      if (addressComponent.types[0] === 'postal_code') {
        zipcode = addressComponent.long_name;
      }
    });

    dispatch(updateAddressFieldsSuccess({ address1, city, state, zipcode }));
  } catch (err) {
    dispatch(updateAddressFieldsFail(err.response ? err.response.data : err));
  }
};

export const fetchSystemLog = (system_id, when, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchCountersFail());
    return;
  }
  let amount, time;
  if (when === 'one_month') {
    amount = 1;
    time = 'month';
  }
  if (when === 'three_month') {
    amount = 3;
    time = 'month';
  }
  if (when === 'one_year') {
    amount = 1;
    time = 'year';
  }
  dispatch(fetchSystemLogReq());
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios
    .get(`/admin/fetchSystemLog/${system_id}/${amount}/${time}`)
    .then(({ data }) => {
      dispatch(fetchSystemLogSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        history.push('/dashboard/login');
      }
      dispatch(fetchSystemLogFail(err.response.data || err));
    });
};

export const changeRole = (access_id, user_role, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(changeRoleFail());
    return;
  }

  const user_info = {
    access_id,
    user_role,
  };

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios
    .post(`/admin/changeRole`, user_info)
    .then(({ data }) => {
      dispatch(changeRoleSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        history.push('/dashboard/login');
      }
      dispatch(changeRoleFail(err.response.data || err));
    });
};

export const removeAccess = (access_id, removedUserName, history) => (
  dispatch
) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchCountersFail());
    return;
  }
  dispatch(removeAccessReq());

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios
    .post(`/admin/removeAccess`, { access_id })
    .then(({ data }) => {
      dispatch(success(removeUserSuccess(removedUserName)));
      dispatch(removeAccessSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        history.push('/dashboard/login');
      }
      dispatch(removeAccessFail(err.response.data || err));
    });
};

export const removeAccessPopUp = (access_id, userName, history) => (
  dispatch
) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchCountersFail());
    return;
  }

  dispatch(removeAccessPopUpReq({ userName: userName, accessId: access_id }));

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios
    .post(`/admin/removeAccess`, { access_id })
    .then(({ data }) => {
      dispatch(success(removeUserSuccess(userName)));
      dispatch(removeAccessSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        history.push('/dashboard/login');
      }
      dispatch(removeAccessFail(err.response.data || err));
    });
};

export const clearRemoveAccessPopUp = () => (dispatch) => {
  dispatch(clearRemoveAccessPopUpReq());
};
