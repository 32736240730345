import React from 'react';
import './locker_icons.css';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.71 157.51">
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Doors">
        <line
          className="locker-icon"
          x1="45.85"
          y1="142.25"
          x2="45.85"
          y2="142.15"
        />
        <line class="cls-1" x1="32.75" y1="142.15" x2="32.75" y2="142.25" />
        <line class="cls-1" x1="10.35" y1="12.15" x2="10.35" y2="12.15" />
        <line class="cls-1" x1="68.35" y1="12.15" x2="68.35" y2="12.15" />
        <line class="cls-1" x1="32.85" y1="147.35" x2="32.85" y2="147.45" />
        <line class="cls-1" x1="45.85" y1="147.45" x2="45.85" y2="147.35" />
        <line class="cls-1" x1="7.45" y1="116.75" x2="7.45" y2="152.75" />
        <line class="cls-1" x1="71.25" y1="116.75" x2="7.45" y2="116.75" />
        <line class="cls-1" x1="71.25" y1="152.75" x2="71.25" y2="116.75" />
        <line class="cls-1" x1="7.45" y1="152.75" x2="71.25" y2="152.75" />
        <line class="cls-1" x1="7.45" y1="6.85" x2="7.45" y2="112.05" />
        <line class="cls-1" x1="71.25" y1="6.85" x2="7.45" y2="6.85" />
        <line class="cls-1" x1="71.25" y1="112.05" x2="71.25" y2="6.85" />
        <line class="cls-1" x1="7.45" y1="112.05" x2="71.25" y2="112.05" />
        <line class="cls-1" x1="78.35" y1="0.35" x2="0.35" y2="0.35" />
        <line class="cls-1" x1="78.35" y1="157.15" x2="78.35" y2="0.35" />
        <line class="cls-1" x1="0.35" y1="0.35" x2="0.35" y2="157.15" />
        <line class="cls-1" x1="0.35" y1="157.15" x2="78.35" y2="157.15" />
        <line class="cls-1" x1="71.15" y1="10.05" x2="71.15" y2="10.05" />
        <line class="cls-1" x1="7.45" y1="10.05" x2="7.45" y2="10.05" />
        <line class="cls-1" x1="10.35" y1="12.05" x2="68.35" y2="12.05" />
        <polyline
          class="cls-1"
          points="10.35 12.05 10.25 12.05 10.05 12.05 9.85 12.15 9.75 12.26 9.65 12.35 9.55 12.55 9.55 12.76 9.45 12.85"
        />
        <line class="cls-1" x1="10.35" y1="11.95" x2="10.35" y2="12.05" />
        <polyline
          class="cls-1"
          points="69.15 12.85 69.15 12.76 69.15 12.55 69.05 12.35 68.95 12.26 68.75 12.15 68.65 12.05 68.45 12.05 68.35 12.05"
        />
        <line class="cls-1" x1="68.35" y1="12.05" x2="68.35" y2="11.95" />
        <line class="cls-1" x1="71.15" y1="10.05" x2="71.15" y2="6.95" />
        <line class="cls-1" x1="7.45" y1="10.05" x2="7.45" y2="6.95" />
        <line class="cls-1" x1="7.45" y1="10.05" x2="71.15" y2="10.05" />
        <line class="cls-1" x1="68.35" y1="110.05" x2="10.35" y2="110.05" />
        <polyline
          class="cls-1"
          points="10.35 110.06 10.25 109.95 10.05 109.95 9.85 109.85 9.75 109.75 9.65 109.66 9.55 109.45 9.55 109.35 9.45 109.16"
        />
        <line class="cls-1" x1="9.45" y1="109.15" x2="9.45" y2="12.85" />
        <polyline
          class="cls-1"
          points="9.45 12.85 9.55 12.65 9.55 12.46 9.65 12.35 9.75 12.26 9.85 12.15 10.05 12.05 10.25 11.96 10.35 11.96"
        />
        <line class="cls-1" x1="10.35" y1="11.95" x2="68.35" y2="11.95" />
        <polyline
          class="cls-1"
          points="68.35 11.96 68.45 11.96 68.65 12.05 68.75 12.15 68.95 12.26 69.05 12.35 69.15 12.46 69.15 12.65 69.15 12.85"
        />
        <line class="cls-1" x1="69.15" y1="12.85" x2="69.15" y2="109.15" />
        <polyline
          class="cls-1"
          points="69.15 109.16 69.15 109.35 69.15 109.45 69.05 109.66 68.95 109.75 68.75 109.85 68.65 109.95 68.45 109.95 68.35 110.06"
        />
        <line class="cls-1" x1="7.45" y1="10.05" x2="71.15" y2="10.05" />
        <line class="cls-1" x1="71.15" y1="112.05" x2="71.15" y2="111.95" />
        <line class="cls-1" x1="7.45" y1="112.05" x2="7.45" y2="111.95" />
        <polyline
          class="cls-1"
          points="71.15 111.95 71.15 111.95 71.15 111.85"
        />
        <line class="cls-1" x1="7.45" y1="111.85" x2="71.15" y2="111.85" />
        <polyline class="cls-1" points="7.45 111.95 7.45 111.95 7.45 111.85" />
        <line class="cls-1" x1="7.45" y1="111.95" x2="71.15" y2="111.95" />
        <line class="cls-1" x1="71.15" y1="6.95" x2="71.15" y2="6.85" />
        <line class="cls-1" x1="7.45" y1="6.95" x2="7.45" y2="6.85" />
        <line class="cls-1" x1="7.45" y1="6.95" x2="71.15" y2="6.95" />
        <line class="cls-1" x1="71.15" y1="111.95" x2="71.25" y2="111.95" />
        <line class="cls-1" x1="71.15" y1="111.85" x2="71.15" y2="10.05" />
        <polyline
          class="cls-1"
          points="71.15 111.85 71.15 111.85 71.25 111.85"
        />
        <polyline class="cls-1" points="71.25 10.05 71.25 10.05 71.15 10.05" />
        <line class="cls-1" x1="7.45" y1="111.95" x2="7.45" y2="111.95" />
        <line class="cls-1" x1="7.45" y1="111.85" x2="7.45" y2="10.05" />
        <line class="cls-1" x1="7.45" y1="10.05" x2="7.45" y2="10.05" />
        <line class="cls-1" x1="7.45" y1="111.85" x2="7.45" y2="111.85" />
        <line class="cls-1" x1="7.45" y1="136.55" x2="71.25" y2="136.55" />
        <line class="cls-1" x1="7.45" y1="133.05" x2="71.25" y2="133.05" />
        <polyline
          class="cls-1"
          points="46.45 142.75 46.45 142.66 46.35 142.56"
        />
        <polyline
          class="cls-1"
          points="32.35 142.56 32.35 142.56 32.25 142.56 32.25 142.66 32.25 142.75"
        />
        <line class="cls-1" x1="37.05" y1="123.75" x2="41.65" y2="123.75" />
        <polyline
          class="cls-1"
          points="41.65 123.75 41.75 123.75 41.85 123.75 41.95 123.66 41.95 123.56"
        />
        <line class="cls-1" x1="41.65" y1="123.95" x2="41.65" y2="123.75" />
        <polyline
          class="cls-1"
          points="36.65 123.56 36.65 123.56 36.75 123.66 36.75 123.75 36.85 123.75 36.95 123.75 37.05 123.75"
        />
        <line class="cls-1" x1="37.05" y1="123.75" x2="37.05" y2="123.95" />
        <line class="cls-1" x1="36.65" y1="122.25" x2="36.65" y2="123.75" />
        <polyline
          class="cls-1"
          points="36.65 122.25 36.65 122.16 36.75 122.16 36.75 122.06 36.85 122.06 36.95 121.95 37.05 121.95"
        />
        <line class="cls-1" x1="41.65" y1="121.95" x2="37.05" y2="121.95" />
        <polyline
          class="cls-1"
          points="41.65 121.95 41.75 121.95 41.85 122.06 41.95 122.06 41.95 122.16 41.95 122.25"
        />
        <line class="cls-1" x1="41.95" y1="123.75" x2="41.95" y2="122.25" />
        <polyline
          class="cls-1"
          points="41.95 123.75 41.95 123.75 41.95 123.85 41.85 123.85 41.85 123.95 41.75 123.95 41.65 123.95"
        />
        <line class="cls-1" x1="37.05" y1="123.95" x2="41.65" y2="123.95" />
        <polyline
          class="cls-1"
          points="37.05 123.95 36.95 123.95 36.85 123.95 36.75 123.85 36.65 123.75"
        />
        <line class="cls-1" x1="7.45" y1="132.95" x2="71.25" y2="132.95" />
        <line class="cls-1" x1="32.25" y1="142.55" x2="32.25" y2="147.05" />
        <polyline
          class="cls-1"
          points="32.25 142.56 32.25 142.46 32.35 142.35 32.45 142.25 32.55 142.25 32.65 142.16 32.75 142.16"
        />
        <line class="cls-1" x1="45.85" y1="142.15" x2="32.75" y2="142.15" />
        <polyline
          class="cls-1"
          points="45.85 142.16 45.95 142.16 46.05 142.25 46.15 142.25 46.25 142.25 46.35 142.35 46.35 142.46 46.45 142.46 46.45 142.56"
        />
        <line class="cls-1" x1="46.45" y1="147.05" x2="46.45" y2="142.55" />
        <polyline
          class="cls-1"
          points="46.45 147.06 46.45 147.16 46.35 147.25 46.25 147.35 46.15 147.35 46.05 147.46 45.95 147.46 45.85 147.46"
        />
        <line class="cls-1" x1="32.75" y1="147.45" x2="45.85" y2="147.45" />
        <polyline
          class="cls-1"
          points="32.75 147.46 32.65 147.46 32.55 147.46 32.45 147.35 32.35 147.25 32.25 147.25 32.25 147.16 32.25 147.06"
        />
        <line class="cls-1" x1="7.45" y1="136.55" x2="71.25" y2="136.55" />
        <line class="cls-1" x1="7.45" y1="116.85" x2="71.25" y2="116.85" />
        <line class="cls-1" x1="7.45" y1="152.65" x2="71.25" y2="152.65" />
        <polyline
          class="cls-1"
          points="9.65 12.85 9.65 12.76 9.75 12.55 9.75 12.46 9.85 12.35 9.95 12.26 10.15 12.15 10.25 12.15 10.35 12.15"
        />
        <polyline
          class="cls-1"
          points="68.35 12.15 68.45 12.15 68.55 12.15 68.75 12.26 68.85 12.35 68.85 12.46 68.95 12.55 69.05 12.76 69.05 12.85"
        />
        <polyline
          class="cls-1"
          points="40.45 13.96 40.35 13.76 40.35 13.55 40.15 13.26 39.95 13.15 39.75 12.96 39.45 12.96 39.25 12.96 38.95 12.96 38.75 13.15 38.55 13.26 38.35 13.55 38.25 13.76 38.25 13.96"
        />
        <line class="cls-1" x1="62.95" y1="19.55" x2="15.75" y2="19.55" />
        <line class="cls-1" x1="10.35" y1="109.85" x2="10.35" y2="109.85" />
        <line class="cls-1" x1="68.35" y1="109.85" x2="68.35" y2="109.85" />
        <polyline class="cls-1" points="9.75 12.85 9.75 12.85 9.65 12.85" />
        <line class="cls-1" x1="9.65" y1="109.15" x2="9.65" y2="12.85" />
        <polyline class="cls-1" points="69.05 12.85 69.05 12.85 68.95 12.85" />
        <line class="cls-1" x1="69.05" y1="12.85" x2="69.05" y2="109.15" />
        <line class="cls-1" x1="9.65" y1="109.15" x2="9.65" y2="109.15" />
        <polyline
          class="cls-1"
          points="9.65 109.16 9.65 109.35 9.75 109.45 9.75 109.56 9.85 109.66 9.95 109.75 10.15 109.85 10.25 109.85 10.35 109.85"
        />
        <line class="cls-1" x1="10.35" y1="109.85" x2="10.35" y2="109.85" />
        <line class="cls-1" x1="10.35" y1="109.85" x2="68.35" y2="109.85" />
        <line class="cls-1" x1="68.35" y1="109.85" x2="68.35" y2="109.85" />
        <polyline
          class="cls-1"
          points="68.35 109.85 68.45 109.85 68.55 109.85 68.75 109.75 68.85 109.66 68.85 109.56 68.95 109.45 69.05 109.35 69.05 109.16"
        />
        <line class="cls-1" x1="69.05" y1="109.15" x2="69.05" y2="109.15" />
        <line class="cls-1" x1="68.95" y1="109.15" x2="68.95" y2="12.85" />
        <polyline
          class="cls-1"
          points="68.95 12.85 68.95 12.76 68.95 12.55 68.85 12.46 68.75 12.35 68.65 12.26 68.55 12.26 68.45 12.15 68.35 12.15"
        />
        <line class="cls-1" x1="68.35" y1="12.15" x2="10.35" y2="12.15" />
        <polyline
          class="cls-1"
          points="10.35 12.15 10.25 12.15 10.15 12.26 10.05 12.26 9.95 12.35 9.85 12.46 9.75 12.55 9.75 12.76 9.75 12.85"
        />
        <line class="cls-1" x1="9.75" y1="12.85" x2="9.75" y2="109.15" />
        <polyline
          class="cls-1"
          points="39.35 15.05 39.65 14.96 39.85 14.85 40.05 14.76 40.25 14.55 40.35 14.26 40.45 14.05 40.35 13.76 40.35 13.55 40.15 13.26 39.95 13.15 39.75 12.96 39.45 12.96 39.25 12.96 38.95 12.96 38.75 13.15 38.55 13.26 38.35 13.55 38.25 13.76 38.25 14.05 38.35 14.26 38.45 14.55 38.65 14.76 38.85 14.85 39.05 14.96 39.35 15.05"
        />
        <line class="cls-1" x1="15.75" y1="19.55" x2="15.75" y2="102.45" />
        <line class="cls-1" x1="62.95" y1="19.55" x2="15.75" y2="19.55" />
        <line class="cls-1" x1="62.95" y1="102.45" x2="62.95" y2="19.55" />
        <line class="cls-1" x1="15.75" y1="102.45" x2="62.95" y2="102.45" />
        <polyline class="cls-1" points="9.75 109.16 9.75 109.16 9.65 109.16" />
        <polyline
          class="cls-1"
          points="10.35 109.85 10.25 109.85 10.15 109.75 9.95 109.75 9.85 109.66 9.75 109.56 9.75 109.45 9.65 109.25 9.65 109.16"
        />
        <polyline
          class="cls-1"
          points="9.75 109.16 9.75 109.25 9.75 109.35 9.85 109.45 9.95 109.56 10.05 109.66 10.15 109.75 10.25 109.75 10.35 109.75"
        />
        <polyline
          class="cls-1"
          points="10.35 109.75 10.35 109.75 10.35 109.85"
        />
        <line class="cls-1" x1="68.35" y1="109.85" x2="10.35" y2="109.85" />
        <line class="cls-1" x1="10.35" y1="109.75" x2="68.35" y2="109.75" />
        <polyline
          class="cls-1"
          points="68.35 109.75 68.35 109.75 68.35 109.85"
        />
        <polyline
          class="cls-1"
          points="69.05 109.16 69.05 109.25 68.95 109.45 68.85 109.56 68.85 109.66 68.75 109.75 68.55 109.75 68.45 109.85 68.35 109.85"
        />
        <polyline
          class="cls-1"
          points="69.05 109.16 69.05 109.16 68.95 109.16"
        />
        <polyline
          class="cls-1"
          points="68.35 109.75 68.45 109.75 68.55 109.75 68.65 109.66 68.75 109.56 68.85 109.45 68.95 109.35 68.95 109.25 68.95 109.16"
        />
        <line class="cls-1" x1="41.65" y1="123.75" x2="37.05" y2="123.75" />
        <line class="cls-1" x1="32.35" y1="147.15" x2="32.35" y2="147.05" />
        <line class="cls-1" x1="46.35" y1="147.05" x2="46.35" y2="147.15" />
        <line class="cls-1" x1="32.85" y1="147.35" x2="45.85" y2="147.35" />
        <polyline
          class="cls-1"
          points="45.85 147.35 45.95 147.35 46.05 147.35 46.15 147.35 46.15 147.25 46.25 147.25 46.25 147.16 46.35 147.06"
        />
        <line class="cls-1" x1="46.35" y1="147.05" x2="46.35" y2="142.65" />
        <polyline
          class="cls-1"
          points="46.35 142.66 46.35 142.56 46.25 142.46 46.15 142.35 46.05 142.25 45.95 142.25 45.85 142.25"
        />
        <line class="cls-1" x1="45.85" y1="142.25" x2="32.85" y2="142.25" />
        <polyline
          class="cls-1"
          points="32.85 142.25 32.75 142.25 32.65 142.25 32.55 142.35 32.45 142.35 32.45 142.46 32.35 142.46 32.35 142.56 32.35 142.66"
        />
        <line class="cls-1" x1="32.35" y1="142.65" x2="32.35" y2="147.05" />
        <polyline
          class="cls-1"
          points="32.35 147.06 32.35 147.06 32.35 147.16 32.45 147.25 32.55 147.35 32.65 147.35 32.75 147.35 32.85 147.35"
        />
        <line class="cls-1" x1="46.35" y1="147.15" x2="46.35" y2="147.25" />
        <polyline
          class="cls-1"
          points="32.35 147.25 32.35 147.25 32.35 147.16"
        />
        <line class="cls-1" x1="68.35" y1="12.15" x2="10.35" y2="12.15" />
        <line class="cls-1" x1="38.25" y1="13.95" x2="38.25" y2="13.95" />
        <line class="cls-1" x1="10.35" y1="109.85" x2="68.35" y2="109.85" />
      </g>
    </g>
  </svg>
);
