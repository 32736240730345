import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changeSystemSettings,
  addCourier,
  fetchCouriers,
  deleteCourier,
  changeAscending,
  courierWarning,
  updateAddressFields,
} from 'redux/modules/duck-admin';
import { noValuesChangedAlert } from 'redux/modules/duck-auth';
import { reduxForm, Field } from 'redux-form';
import { sortCouriers } from 'selectors/courierSelectors';
import { Prompt } from 'react-router-dom';
import SmartInput from 'react-phone-number-input/smart-input';
// import moment from 'moment-timezone';
import Transfer from './Transfer';
import UploadLogo from './UploadLogo';

import { Header } from 'common/Header';
import { systemSettingsFields } from 'common/forms/formFields';
import renderSystemSetting from 'common/forms/renderSystemSetting';
import renderField from 'common/forms/renderField';
import LongButton from 'common/buttons/longButton.js';
import Spinner from 'common/Spinner';
import LoaderPage from 'common/LoaderPage';
import Box from 'common/forms/FormBox';
import PhoneInput from 'common/PhoneInput';

import colors from 'utils/colors';
import { mobile } from 'utils/media';
import { systemSettingsValidation as validate } from 'utils/validations';
import { baseFont, boldFont } from 'utils/fonts';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  ${mobile(css`
    flex-direction: column;
    width: 100%;
    div {
      width: 100%;
    }
  `)};
`;

const AddressRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: -22px;
  ${mobile(css`
    flex-direction: column;
    width: 100%;
    div {
      width: 100%;
    }
  `)};
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  width: 100%;
  align-items: left;
  ${mobile(css`
    align-items: left;
  `)};
`;

const ToolButton = styled(LongButton)`
  height: 34px;
  width: 221px;
  border: 1px solid #3e3e58;
  border-radius: 2px;

	color: #3E3E58;
	font-family: ${baseFont};
	font-size: 14px;
	letter-spacing: 0.54px;
	line-height: 16px;
	text-align: center;
}

  ${mobile(
    css`
      margin: 15px;
      width: 75%;
    `
  )};
`;

const InputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${mobile(css`
    flex-direction: column;
    margin: 0px 0px 10px 0px;
    padding: 0px;
  `)};
`;

const FocusWrapper = styled.div`
  width: 200px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: inline-block;
  ${mobile(css`
    width: 100%;
  `)};
`;

const InputField = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 0 0 15px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-family: ${baseFont};
  border: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.3);
  transition: all 350ms ease-in-out;
  &::placeholder {
    color: ${colors.primary_gray};
    opacity: 0.75;
    transition: opacity 150ms ease-in-out;
  }
  &:focus {
    outline: none;
    + span {
      &:after {
        transform: scaleX(1.01);
      }
    }
    &::placeholder {
      opacity: 0.1;
    }
  }
  + span {
    &:after {
      margin-top: -1.5px;
      z-index: 999;
      display: block;
      content: '';
      border-bottom: 2px solid ${colors.base_text3};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
  }
`;

const AddButton = styled(LongButton)`
  float: right;
  width: 125px;
  height: 24px;
  ${mobile(css`
    width: 100%;
  `)};

  font-size: 14px;
  letter-spacing: 0.54px;
  line-height: 16px;
  text-align: center;
`;

const Wrapper = styled.div`
  font-size: 18px;
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding: 0 auto;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(4, 96, 132, 0.5);
  }
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(4, 96, 132, 1);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.primary_blue};
  }
`;

const Table = styled.table`
  width: 100%;

  font-size: 15px;
  letter-spacing: 0.68px;
  line-height: 18px;
  color: ${colors.base_text3};
`;

const THeader = styled.tr`
  text-align: left;
  text-indent: 15px;
  font-size: 15px;
  line-height: 18px;
  font-family: ${baseFont};
  font-weight: 900;
  color: ${colors.base_text3};
  background-color: #e8e8e8;
`;

const Th = styled.th`
  padding: 10px 0;
`;

const CRow = styled.tr`
  text-align: left;
  border: 1px solid red;
`;

const Cell = styled.td`
  padding-left: 15px;
  color: #083e66;
  font: ${baseFont};
  font-size: 14px;
  letter-spacing: 0.59px;
  line-height: 46px;
`;

const NA = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: ${colors.base_text3};
  font-family: ${baseFont};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  ${mobile(css`
    flex-direction: column;
    align-items: center;
    align-content: center;
  `)};
`;

const SubTitle = styled.p`
  color: #083e66;
  font-size: 24px;
  font-weight: bold;
  line-height: 22px;
  text-align: left;
  margin: 50px 0 0 0;
`;

const UpdateButton = styled(LongButton)`
  height: 45px;
  width: 257px;
  color: #ffffff;
  font-family: ${baseFont};
  font-size: 15px;
  letter-spacing: 0.58px;
  line-height: 18px;
  text-align: center;
`;

const CloseButton = styled.span`
  float: right;
  fontfamily: Arial;
  lineheight: 15px;
  cursor: pointer;
  color: ${(p) => colors[p.color] || colors.base_text3};
  fontweight: 300;
  width: 14px;
  height: 14px;
  fontsize: 17px;
`;

class SystemSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showUploadModal: false,
      showCourierModal: false,
      addressSearch: '',
      addressInput: '',
      phone: '',
      time_zone: '',
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleCourierModal = this.toggleCourierModal.bind(this);
    this.toggleUploadModal = this.toggleUploadModal.bind(this);
    this.checkChanges = this.checkChanges.bind(this);
    this.getCourierName = this.getCourierName.bind(this);
    this.getCourierCode = this.getCourierCode.bind(this);
    this.addNewCourier = this.addNewCourier.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  componentDidMount() {
    if (this.props.selectedSystem && this.props.selectedSystem.level !== 1) {
      return this.props.history.push('/admin');
    }

    ReactModal.setAppElement('body');
  }
  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  toggleCourierModal(sys_id) {
    this.setState({ showCourierModal: !this.state.showCourierModal });
  }

  toggleUploadModal() {
    this.setState({ showUploadModal: !this.state.showUploadModal });
  }

  checkChanges(values) {
    const selectedSystem = this.props.selectedSystem.system;
    let changedValues = {};

    for (const field in values) {
      if (field === 'address2') {
      }
      if (
        !_.isEqual(
          values[field],
          selectedSystem[field] || selectedSystem.location[field]
        )
      ) {
        changedValues[field] = values[field];
      }
    }

    return changedValues;
  }

  handleAddressChange = (e) => {
    this.setState({
      addressSearch: e.target.value,
      addressInput: e.target.value,
    });
  };

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    if (this.refs.googleSuggest) {
      this.setState({
        addressSearch: '',
        addressInput: geocodedPrediction.formatted_address,
      });
    }

    const originalAddress = originalPrediction.description.split(',');

    this.props.updateAddressFields(geocodedPrediction, originalAddress[0]);
  };

  getCourierName(e) {
    const courier_name = e.currentTarget.value;
    this.setState({ courier_name });
  }

  getCourierCode(e) {
    const courier_code = e.currentTarget.value.toLowerCase();
    this.setState({ courier_code });
  }

  addNewCourier() {
    const selectedSystem = this.props.selectedSystem.system;

    if (!this.state.courier_name || !this.state.courier_code) {
      return this.props.courierWarning('emptyCourier');
    }

    if (this.state.courier_code.length !== 6) {
      return this.props.courierWarning('invalidCourierCode');
    }

    if (isNaN(this.state.courier_code)) {
      return this.props.courierWarning('invalidCourierCode');
    }

    this.props.addCourier(
      selectedSystem._id,
      this.state.courier_name,
      this.state.courier_code
    );

    // Clear the input field after adding.
    this.setState({ courier_name: '' });
    this.setState({ courier_code: '' });
    this.courier_name.value = '';
    this.courier_code.value = '';
  }

  removeCourier(id) {
    this.props.deleteCourier(id, this.props.history);
  }

  onKeyPress(e) {
    if (e.which === 13) {
      this.addNewCourier();
    }
  }

  renderCouriers(couriers) {
    return _.map(couriers, (courier) => {
      return (
        <CRow key={courier._id}>
          <Cell>{courier.name}</Cell>
          <Cell>
            {courier.code}
            <CloseButton
              color="action2"
              onClick={() =>
                this.props.deleteCourier(
                  courier._id,
                  courier._system,
                  courier.name,
                  courier.code
                )
              }
            >
              x
            </CloseButton>
          </Cell>
        </CRow>
      );
    });
  }

  // renderAutoCompleteFields(addressInput, handleAddressChange) {
  //   const selectedSystem = this.props.selectedSystem.system;
  //   const fields = systemSettingsFields.slice(0, 2);
  //   return _.map(fields, (field, index) => {
  //     const { name, label } = field;
  //     return (
  //       <AddressRow key={index}>
  //         <Field
  //           key={selectedSystem._id + index}
  //           name={name}
  //           label={label}
  //           component={renderField}
  //           placeholder={label}
  //           value={name === 'address1' ? addressInput : null}
  //           onChange={name === 'address1' ? handleAddressChange : null}
  //           formtype="systemSettings"
  //         />
  //       </AddressRow>
  //     );
  //   });
  // }

  renderAddressRestFields() {
    const selectedSystem = this.props.selectedSystem.system;

    return _.map(systemSettingsFields, (field, index) => {
      // debugger;
      if (field.length) {
        const row = field;
        return (
          <Row key={index}>
            {_.map(row, (setting, index) => {
              const { name, label } = setting;
              return name !== 'phone' ? (
                <Field
                  key={selectedSystem._id + index}
                  name={name}
                  label={label}
                  placeholder={label}
                  component={renderField}
                  defaultValue={this.props.initialValues[`${name}`]}
                  formtype="systemSettings"
                  row="true"
                />
              ) : (
                <PhoneInput
                  formtype="register"
                  inputComponent={SmartInput}
                  country="US"
                  placeholder="Phone Number"
                  value={
                    this.props.systems && !this.state.phone
                      ? this.props.initialValues.phone
                      : this.state.phone
                  }
                  onChange={(value) => this.props.change('phone', value)}
                  style={{ paddingTop: '25px', display: 'inline-block' }}
                />
              );
            })}
          </Row>
        );
      } else {
        const { name, label } = field;

        return (
          <Row key={selectedSystem._id + index}>
            <Field
              key={selectedSystem._id + index}
              name={name}
              label={label}
              component={renderField}
              placeholder={label}
              defaultValue={this.props.initialValues[`${name}`]}
              formtype="systemSettings"
              row="true"
            />
          </Row>
        );
      }
    });
  }

  render() {
    const {
      systems,
      changeSystemSettings,
      loading,
      table_loading,
      ascending,
    } = this.props;
    const { addressSearch, addressInput } = this.state;
    const selectedSystem = this.props.selectedSystem
      ? this.props.selectedSystem.system
      : null;

    const direction = ascending ? 'icon-sort-down' : 'icon-sort-up';

    return (
      <Header style={{ marginTop: '25px' }}>
        {selectedSystem && !loading ? (
          <div style={{ width: '100%' }}>
            <FormWrapper
              autoComplete="off"
              onSubmit={this.props.handleSubmit(() => {
                const changedValues = this.checkChanges(
                  this.props.systemSettingsForm.values
                );
                if (!changedValues.system_id) {
                  changedValues.system_id = selectedSystem._id;
                }
                if (
                  Object.keys(changedValues).length > 1 &&
                  changedValues.constructor === Object
                ) {
                  changeSystemSettings(
                    changedValues,
                    _.map(systems, (sys_obj) => {
                      return sys_obj.system._id;
                    }).indexOf(selectedSystem._id),
                    this.props.history
                  );
                } else {
                  this.props.noValuesChangedAlert();
                }
              })}
            >
              <Prompt
                when={this.props.dirty}
                message={(location) =>
                  `Are you sure you want to leave without save?`
                }
              />
              <FieldWrapper>
                <SubTitle style={{ margin: 0 }}>System Information</SubTitle>
                <Box>
                  {this.renderAddressRestFields()}
                  <Field
                    name="timezone"
                    label="Timezone"
                    defaultValueTime={selectedSystem.timezone}
                    component={renderSystemSetting}
                  />
                </Box>
              </FieldWrapper>
              <UpdateButton
                textColor="white"
                backgroundColor="base_text3"
                type="submit"
                style={{ overflow: 'hidden' }}
              >
                {loading ? <Loader /> : 'UPDATE'}
              </UpdateButton>
            </FormWrapper>

            <ReactModal
              className="Modal"
              overlayClassName="Overlay"
              system={selectedSystem}
              closeTimeoutMS={500}
              isOpen={this.state.showCourierModal}
              onRequestClose={this.toggleCourierModal}
              shouldCloseOnOverlayClick
              shouldCloseOnEsc
            >
              <InputWrapper>
                <FocusWrapper>
                  <InputField
                    type="text"
                    placeholder="Courier Name"
                    onChange={this.getCourierName}
                    onKeyPress={this.onKeyPress}
                    innerRef={(ref) => (this.courier_name = ref)}
                  />
                  <span />
                </FocusWrapper>
                <FocusWrapper>
                  <InputField
                    type="text"
                    placeholder="Code"
                    maxLength="6"
                    onChange={this.getCourierCode}
                    onKeyPress={this.onKeyPress}
                    innerRef={(ref) => (this.courier_code = ref)}
                  />
                  <span />
                </FocusWrapper>

                <AddButton
                  textColor="white"
                  backgroundColor="base_text2"
                  onClick={this.addNewCourier}
                >
                  Add
                </AddButton>
              </InputWrapper>
              <Wrapper>
                <Table>
                  <tbody>
                    <THeader>
                      <Th
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.props.changeAscending()}
                      >
                        <p style={{ margin: '0' }}>
                          Courier
                          <i className={direction} />
                        </p>
                      </Th>
                      <Th>Code</Th>
                    </THeader>
                    {this.props.couriers &&
                    this.props.couriers.length === 0 &&
                    !table_loading ? (
                      <tr>
                        <td colSpan="5">
                          <NA>No Couriers assigned yet!</NA>
                        </td>
                      </tr>
                    ) : table_loading ? (
                      <tr>
                        <td>
                          <LoaderPage color="accent1" />
                        </td>
                      </tr>
                    ) : (
                      this.renderCouriers(this.props.couriers)
                    )}
                  </tbody>
                </Table>
              </Wrapper>
            </ReactModal>

            <ReactModal
              className="Modal"
              overlayClassName="Overlay"
              system={selectedSystem}
              closeTimeoutMS={500}
              isOpen={this.state.showUploadModal}
              onRequestClose={this.toggleUploadModal}
              shouldCloseOnOverlayClick
              shouldCloseOnEsc
            >
              <UploadLogo
                system_id={selectedSystem._id}
                history={this.props.history}
                toggleUploadModal={this.toggleUploadModal}
              />
            </ReactModal>

            <ReactModal
              className="Modal"
              overlayClassName="Overlay"
              closeTimeoutMS={500}
              isOpen={this.state.showModal}
              onRequestClose={this.toggleModal}
              shouldCloseOnOverlayClick
              shouldCloseOnEsc
            >
              <Transfer
                system_id={selectedSystem._id}
                history={this.props.history}
                toggleModal={this.toggleModal}
              />
            </ReactModal>
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              height: '400px',
            }}
          >
            <LoaderPage color="accent1" />
          </div>
        )}
      </Header>
    );
  }
}

function mapStateToProps({ admin, form }) {
  const { systemSettingsForm } = form;

  const {
    systems,
    loading,
    selectedSystem,
    ascending,
    table_loading,
    addressFields,
  } = admin;
  let initialValues = null;
  if (selectedSystem) {
    let { system } = selectedSystem;
    if (addressFields) {
      initialValues = {
        system_id: system._id,
        nickname: system.nickname,
        courier_code: system.courier_code,
        address1: system.address1,
        address2: system.location.address2 || '',
        city: addressFields.city,
        state: addressFields.state,
        zip: addressFields.zipcode,
        manager_pick_up_time: system.manager_pick_up_time,
        notification_freq: system.notification_freq,
        vacation_limit: system.vacation_limit,
        timezone: system.timezone,
        parcel_expiration: system.parcel_expiration,
      };
    } else {
      initialValues = {
        system_id: system._id,
        nickname: system.nickname,
        courier_code: system.courier_code,
        address1: system.location.address1,
        address2: system.location.address2 || '',
        city: system.location.city,
        state: system.location.state,
        zip: system.location.zip,
        email: system.email,
        phone: system.phone,
        manager_pick_up_time: system.manager_pick_up_time,
        notification_freq: system.notification_freq,
        vacation_limit: system.vacation_limit,
        timezone: system.timezone,
        parcel_expiration: system.parcel_expiration,
      };
    }
  }
  return {
    systems,
    loading,
    selectedSystem,
    couriers: sortCouriers(admin),
    ascending,
    table_loading,
    systemSettingsForm,
    initialValues,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSystemSettings,
      noValuesChangedAlert,
      addCourier,
      fetchCouriers,
      deleteCourier,
      changeAscending,
      courierWarning,
      updateAddressFields,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: 'systemSettingsForm',
    touchOnBlur: false,
    enableReinitialize: true,
  })(SystemSettings)
);
