import React from 'react';

import SvgIcon from 'common/icons/SvgIcon';

import colors from 'utils/colors';

export default (props) => {
  const { step } = props;
  return (
    <g id="Phone-Hover-tip" transform="translate(-451.000000, -57.000000)" />
  );
};
